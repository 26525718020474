import React, { useState } from 'react';

import { PlusOutlined, DownloadOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Tabs, Button, Space, DatePicker } from 'antd';
import type { TabsProps } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Style from './TabHeader.module.less';

interface TabHeaderProps {
  tabs: TabsProps['items'];
  onAddProposal?: () => void;
  onAddMorearise?: () => void;
  onDownload?: () => void;
  onSelectDate?: (date: dayjs.Dayjs | null) => void;
}

const TabHeader: React.FC<TabHeaderProps> = ({ tabs, onAddProposal, onDownload, onSelectDate,onAddMorearise }) => {
  const { t } = useTranslation('tabheader');

  const [activeTab, setActiveTab] = useState<string>('1');

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };
  return (
    <div>
      <div className={Style.tabContainer}>
        <Tabs defaultActiveKey="1"onChange={handleTabChange} tabBarExtraContent={
          <div className={Style.tabActions}>
            {activeTab === '2' && (
            <Space direction="vertical" size={12} className={Style.datePicker}>
              <DatePicker onChange={onSelectDate} />
            </Space>
            )}
            {activeTab === '3' && (
              <Button
                icon={<PlusOutlined />}
                type="primary"
                style={{ marginRight: 8, borderRadius: '20px' }}
                onClick={onAddMorearise}
              >
                {t('More arise')}
              </Button>
            )}
            {activeTab !== '3' && (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={{ marginRight: 8, borderRadius: '20px' }}
              onClick={onAddProposal}
            >
               {t('Add Proposal')}
            </Button>
            )}
            <Button
              icon={<DownloadOutlined />}
              style={{ marginRight: 8 }}
              onClick={onDownload}
            >
            </Button>
            <Button icon={<EllipsisOutlined />} />
          </div>
        }
        items={tabs}
        >
          {/* {tabs?.map(tab => (
            <Tabs.TabPane key={tab.key} tab={tab.label}>
              {tab.children}
            </Tabs.TabPane>
          ))} */}
        </Tabs>
      </div>
    </div>
  );
};

export default TabHeader;
