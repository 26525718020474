import React from 'react';

import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import styles from './Depot.module.css';

interface DataType {
  key: string;
  maKho: string;
  maVatTu: string;
  tenVatTu: string;
  dvt: string;
  tonDau: number;
  duDau: number;
  luongNhap: string;
  tienNhap: string;
  luongXuat: string;
  tienXuat: string;
  tonCuoi: number;
  duCuoi: number;
}

const data: DataType[] = [
  {
    key: '1',
    maKho: 'Anthuong3',
    maVatTu: '5x5 (2-3m)',
    tenVatTu: 'Sắt hộp 5x5 (2m - 3m)',
    dvt: 'Cây',
    tonDau: 4567,
    duDau: 4567,
    luongNhap: 'Đinh',
    tienNhap: 'Đinh',
    luongXuat: 'Kg',
    tienXuat: 'Kg',
    tonCuoi: 5,
    duCuoi: 5,
  },
  {
    key: '2',
    maKho: 'Anthuong3',
    maVatTu: '5x10 (2-4m)',
    tenVatTu: 'Sắt hộp 5x10 (2m- 4m)',
    dvt: 'Cây',
    tonDau: 4567,
    duDau: 4567,
    luongNhap: 'Đinh',
    tienNhap: 'Đinh',
    luongXuat: 'Kg',
    tienXuat: 'Kg',
    tonCuoi: 5,
    duCuoi: 5,
  },
  {
    key: '3',
    maKho: 'Anthuong3',
    maVatTu: '5x10 (2,5-4m)',
    tenVatTu: 'Sắt hộp 5x10 (2,5m- 4m)',
    dvt: 'Cây',
    tonDau: 4567,
    duDau: 4567,
    luongNhap: 'Đinh',
    tienNhap: 'Đinh',
    luongXuat: 'Kg',
    tienXuat: 'Kg',
    tonCuoi: 5,
    duCuoi: 5,
  },
  {
    key: '4',
    maKho: 'Anthuong3',
    maVatTu: '5x5 (1,5-3m)',
    tenVatTu: 'Sắt hộp 5x5 (1,5m - 3m)',
    dvt: 'Cây',
    tonDau: 4567,
    duDau: 4567,
    luongNhap: 'Đinh',
    tienNhap: 'Đinh',
    luongXuat: 'Kg',
    tienXuat: 'Kg',
    tonCuoi: 5,
    duCuoi: 5,
  },
  {
    key: '5',
    maKho: 'Anthuong3',
    maVatTu: 'TY',
    tenVatTu: 'Ty 1,2m',
    dvt: 'Thanh',
    tonDau: 4567,
    duDau: 4567,
    luongNhap: 'Đinh',
    tienNhap: 'Đinh',
    luongXuat: 'Kg',
    tienXuat: 'Kg',
    tonCuoi: 5,
    duCuoi: 5,
  },
  {
    key: '6',
    maKho: 'Anthuong3',
    maVatTu: 'BAT',
    tenVatTu: 'Bát',
    dvt: 'Cái',
    tonDau: 4567,
    duDau: 4567,
    luongNhap: 'Đinh',
    tienNhap: 'Đinh',
    luongXuat: 'Kg',
    tienXuat: 'Kg',
    tonCuoi: 5,
    duCuoi: 5,
  },
  {
    key: '7',
    maKho: 'Anthuong3',
    maVatTu: 'VANNGUYEN',
    tenVatTu: 'Ván nguyên',
    dvt: 'Tấm',
    tonDau: 4567,
    duDau: 4567,
    luongNhap: 'Đinh',
    tienNhap: 'Đinh',
    luongXuat: 'Kg',
    tienXuat: 'Kg',
    tonCuoi: 5,
    duCuoi: 5,
  },
  {
    key: '8',
    maKho: 'Anthuong3',
    maVatTu: 'QUEHAN',
    tenVatTu: 'Que hàn 3,2ly',
    dvt: 'Thùng',
    tonDau: 4567,
    duDau: 4567,
    luongNhap: 'Đinh',
    tienNhap: 'Đinh',
    luongXuat: 'Kg',
    tienXuat: 'Kg',
    tonCuoi: 5,
    duCuoi: 5,
  },
];

const Depot: React.FC = () => {
  const { t } = useTranslation('material');
  const columns: ColumnsType<DataType> = [
    {
      title: <span className={styles['header-bold']}>{t('Numerical order')}</span>,
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: 70,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Warehouse code')}</span>,
      dataIndex: 'maKho',
      key: 'maKho',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Material code')}</span>,
      dataIndex: 'maVatTu',
      key: 'maVatTu',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Material name')}</span>,
      dataIndex: 'tenVatTu',
      key: 'tenVatTu',
      align: 'center',
      width: 323,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Unit of measure1')}</span>,

      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Beginning Inventory')}</span>,
      dataIndex: 'tonDau',
      key: 'tonDau',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Opening Balance')}</span>,
      dataIndex: 'duDau',
      key: 'duDau',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Input Amount')}</span>,
      dataIndex: 'luongNhap',
      key: 'luongNhap',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Input Cash')}</span>,
      dataIndex: 'tienNhap',
      key: 'tienNhap',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Output Amount')}</span>,
      dataIndex: 'luongXuat',
      key: 'luongXuat',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Output Cash')}</span>,
      dataIndex: 'tienXuat',
      key: 'tienXuat',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Ending Inventory')}</span>,
      dataIndex: 'tonCuoi',
      key: 'tonCuoi',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
    {
      title: <span className={styles['header-bold']}>{t('Ending Balance')}</span>,
      dataIndex: 'duCuoi',
      key: 'duCuoi',
      align: 'center',
      width: 122,
      className: styles.tablecell,
    },
  ];
  return <Table columns={columns} dataSource={data} className={styles.table} pagination={false} scroll={{ x: 1823 }} />;
};

export default Depot;
