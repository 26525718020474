import React, { useState } from 'react';

import { DownloadOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import './TabHeaderDiary.css';
interface TabHeaderDiaryProps {
  onDownload: () => void;
  onSelectDate: (dates: [Dayjs | null, Dayjs | null] | null) => void;
  onEllipOutLine?: () => void;
}
const TabHeaderDiary: React.FC<TabHeaderDiaryProps> = ({ onSelectDate, onDownload, onEllipOutLine }) => {
  const [selectedDates, setSelectedDates] = useState<[Dayjs | null, Dayjs | null] | null>(null);

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    setSelectedDates(dates);
  };
  const handleApply = () => {
    if (selectedDates) {
      onSelectDate(selectedDates);
    } else {
      onSelectDate([null, null]);
    }
  };
  return (
    <div className="tab-header-diary">
      <div className="date-picker-wrapper">
        <DatePicker.RangePicker onChange={handleRangeChange} className="date-picker" />
        <Button type="primary" onClick={handleApply} className="apply-button">
          Apply
        </Button>
      </div>
      <Button icon={<DownloadOutlined />} onClick={onDownload} type="primary" className="download-button" />
      <Button icon={<EllipsisOutlined />} onClick={onEllipOutLine} type="default" className="ellips-button" />
    </div>
  );
};

export default TabHeaderDiary;
