import React from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ProjectInfo, InvestorInfo } from './components';
import styles from './GeneralInformation.module.less';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getCreateProjectInformationValue, projectActions } from '@/store/project';

export const GeneralInformation = () => {
  const { t } = useTranslation(['projects']);

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const createProjectInformationValue = useAppSelector(getCreateProjectInformationValue());

  const handleSubmit = (values: any) => {
    dispatch(projectActions.setCreateProjectCurrentStep(1));
    dispatch(projectActions.setCreateProjectInformationValue(values));
  };

  const AdditionComponent = () => {
    return (
      <Col span={24}>
        <div className={styles.footerContainer}>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.buttonWithIcon}>
              {t('createProject.next')}
              <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </div>
      </Col>
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...createProjectInformationValue,
        projectStartDate: createProjectInformationValue?.projectStartDate
          ? dayjs(createProjectInformationValue.projectStartDate)
          : null,
        projectEndDate: createProjectInformationValue?.projectEndDate
          ? dayjs(createProjectInformationValue.projectEndDate)
          : null,
      }}
      onFinish={handleSubmit}
    >
      <Row gutter={[16, 16]}>
        <Col span={24} lg={24} xl={12}>
          <ProjectInfo />
        </Col>
        <Col span={24} lg={24} xl={12}>
          <InvestorInfo AdditionComponent={<AdditionComponent />} />
        </Col>
      </Row>
    </Form>
  );
};
