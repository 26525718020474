import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { accountingInvoiceURL } = getEnvVars();

export interface ProductDTO {
  id: number;
  madvcs: string;
  cach_tinh_gia: number;
  ma_vt: string;
  ten_vt: string;
  dvt: string;
  tk_vt: string;
  tk_gv: string;
  tk_dt: string;
  tk_tl: string;
  tk_spdd: string;
  in_Lookup: boolean;
  guid: string; //3fa85f64-5717-4562-b3fc-2c963f66afa6
  productType: number;
  vendor: string;
  manufacturers: string;
  shortDescription: string;
  fullDescription: string;
  price: number;
  picture1: string;
  discount: number;
  dvt1: string;
  dvt_TyLeQuyDoi: number;
  createDate: string; //2024-09-13T14:00:11.492Z
}

export interface WareHouseDTO {
  id: number;
  ma_Kho: string;
  ten_Kho: string;
  dia_Chi: string;
  dien_Thoai: string;
  fax: string;
  ma_Nv: string;
  dien_Giai: string;
  in_Lookup: boolean;
  guid: string; //3fa85f64-5717-4562-b3fc-2c963f66afa6
  createDate: string; //2024-09-13T14:35:57.950Z
}
export interface ProductUnitDTO {
  id: number;
  dvt: string;
  ten_Dvt: string;
  dvt_Level: number;
  in_Lookup: boolean;
  guid: string; // 3fa85f64-5717-4562-b3fc-2c963f66afa6
  createDate: string; //2024-09-13T15:28:13.069Z
}
export interface ThietBiDTO {
  id: number;
  ma_Tscd: string;
  ten_Tscd: string;
  madvcs: string;
  tinh_Kh: boolean;
  ngay_Mua: string; //2024-09-13T15:28:13.059Z
  ngay_Kh: string; //2024-09-13T15:28:13.059Z
  ngay_Thoi_Kh: string; //2024-09-13T15:28:13.059Z
  so_Thang_Kh: number;
  ma_Bo_Phan: string;
  tk_Ts: string;
  in_Lookup: boolean;
  ma_Vt: string;
  congCuDungCu: boolean;
  ccdc_So_Luong: number;
  ccdc_Dvt: string;
  chung_Loai: string;
  guid: string; //3fa85f64-5717-4562-b3fc-2c963f66afa6
  createDate: string; //2024-09-13T15:28:13.059Z
  maLoHang: string;
  ghi_Chu: string;
}
export interface MoneyTypeDTO {
  id: number;
  ma_nt: string;
  ten_nt: string;
  is_nguyen_te: boolean;
  in_Lookup: boolean;
  guid: string; //3fa85f64-5717-4562-b3fc-2c963f66afa6
  createDate: string; //2024-09-13T15:28:13.061Z
}

export interface PhieuNhapXuatKhoDTO {
  id: number,
  del: boolean,
  madvcs: string,
  recId: number,
  ma_ct: string,
  ngay_ct: string, //2024-09-15T02:15:41.638Z
  so_ct: string,
  loai_tt: number,
  han_tt: string, //2024-09-15T02:15:41.638Z
  ma_kh: string,
  ma_bo_phan: string,
  nguoi_tt: string,
  nv_bh: string,
  dia_chi: string,
  dien_giai: string,
  ma_nt: string,
  ty_gia: number,
  info: string,
  is_local: boolean,
  release: boolean,
  moduleName: string,
  createDate: string, //2024-09-15T02:15:41.638Z
  deposite: number,
  customerName: string,
  customerAddress: string,
  customerPhone: string,
  recIdparent: number,
  nguoiDuyet1: string,
  nguoiDuyet2: string,
  nguoiDuyet3: string,
  recIdrelation: number,
  guid: string, // 3fa85f64-5717-4562-b3fc-2c963f66afa6
  nguoiDuyet4: string,
  nguoiDuyet5: string,
  guidRelation: string, // 3fa85f64-5717-4562-b3fc-2c963f66afa6
  chiTietHangHoa: ChiTietHangHoaDTO[],
  isNhapKho: boolean,
  hoaDonVAT: HoaDonVATDTO[],
  list_of_extensions: ExtensionDTO[]
}

export interface ChiTietHangHoaDTO {
  id: number,
  recId: number,
  ma_vt: string,
  ma_kho: string,
  so_luong: number,
  gia: number,
  tien: number,
  gia_nt: number,
  tien_nt: number,
  dien_giai: string,
  tk_no: string,
  tk_co: string,
  so_hopdong: string,
  ma_Vv: string,
  ma_Km: string,
  ma_kho1: string,
  tinh_gia_von_truc_tiep: boolean,
  createDate: string, //2024-09-15T02:15:41.638Z
  guid: string, // 3fa85f64-5717-4562-b3fc-2c963f66afa6
  guidRelation: string, // 3fa85f64-5717-4562-b3fc-2c963f66afa6
}

export interface HoaDonVATDTO {
  folioID: string, // 3fa85f64-5717-4562-b3fc-2c963f66afa6
  so_hoa_don: string,
  mau_hoa_don: string,
  ngay_phat_hanh_hoa_don: string, //2024-09-15T02:15:41.638Z
  ma_doi_tuong: string,
  ma_vu_viec: string,
  tk_VAT_no: string,
  tk_VAT_co: string,
  tien_truoc_thue: number,
  ty_le_thue: number,
  tien_thue: number,
  ghi_chu: string
}

export interface ExtensionDTO {
  extName: string,
  extValue: string,
  extDescription: string
}

export interface GetTonKhoDTO {
  madvcs: string,
  danhSachMaHang: string[],
  ngay_kiem_tra: string, // 2024-09-15T02:45:48.186Z
  danhSachMakho: string[]
}

export interface TonKhoDTO {
  ma_vt: string,
  ma_kho: string,
  luong_nhap: number,
  luong_xuat: number,
  luong_ton: number
}

export interface ProposalFormDTO {
  ma_phieu: string, 
  hang_muc: string,
  ngay_tao: string,
  vat_tu_may_moc: VatTuMayMocDeXuatDTO[]
}

export interface VatTuMayMocDeXuatDTO {
  ma: string,
  ten: string,
  dvt: string,
  kl_theo_ke_hoach: string, 
  ton_kho_thuc_te: string,
  kl_con_lai: string,
  de_xuat_lan_nay: string,
  ngay_yeu_cau_nhap_ve?: string,
  ghi_chu: string,
}

class AccountingInvoiceController {
  public Get = {
    GetProduct: (options?: RequestOptions) => {
      return HttpClient.get(`${accountingInvoiceURL}/api/GetProduct`, options);
    },
    GetWareHouse: (options?: RequestOptions) => {
      return HttpClient.get(`${accountingInvoiceURL}/api/GetWareHouse`, options);
    },
    GetProductUnit: (options?: RequestOptions) => {
      return HttpClient.get(`${accountingInvoiceURL}/api/GetProductUnit`, options);
    },
    GetDanhSachThietBi: (options?: RequestOptions) => {
      return HttpClient.get(`${accountingInvoiceURL}/api/GetDanhSachThietBi`, options);
    },
    GetMoneyTypeList: (options?: RequestOptions) => {
      return HttpClient.get(`${accountingInvoiceURL}/api/GetMoneyTypeList`, options);
    },
    GetProposalForm: (options?: RequestOptions) => {
      return HttpClient.get(`${accountingInvoiceURL}/api/ProposalForm`, options);
    },
    GetDanhSachDuyetChi: (options?: RequestOptions) => { // CapDuyet, madvcs, tu_ngay, den_ngay
      return HttpClient.get(`${accountingInvoiceURL}/api/GetDanhSachDuyetChi`, options);
    },
    GetDanhSachDuyetMuaHang: (options?: RequestOptions) => { // madvcs, ngay_de_nghi
      return HttpClient.get(`${accountingInvoiceURL}/api/GetDanhSachDuyetMuaHang`, options);
    },
  };

  public Post = {
    CreatePhieuNhapXuatKho: (input: PhieuNhapXuatKhoDTO, options?: RequestOptions) => {
      return HttpClient.post(`${accountingInvoiceURL}/api/CreatePhieuNhapXuatKho`, input, options);
    },
    CreateProposalForm: (input: ProposalFormDTO, options?: RequestOptions) => {
      return HttpClient.post(`${accountingInvoiceURL}/api/CreateProposalForm`, input, options);
    },
    DuyetChi: (data: number[], options?: RequestOptions) => { // number[]
      return HttpClient.post(`${accountingInvoiceURL}/api/DuyetChi`, data, options);
    },
    HuyDuyetChi: (data: number[], options?: RequestOptions) => { // number[]
      return HttpClient.post(`${accountingInvoiceURL}/api/HuyDuyetChi`, data, options);
    },
    GetTonKho: (data: GetTonKhoDTO,options?: RequestOptions) => { 
      return HttpClient.post(`${accountingInvoiceURL}/api/GetTonKho`, data, options);
    },
    DeletePhieuNhapXuatKho: (ids: number[], options?: RequestOptions) => {
      return HttpClient.post(`${accountingInvoiceURL}/api/DeletePhieuNhapXuatKho`, ids, options);
    },
  };

  public Put = {
    UpdateProposalForm: (id: number, input: ProposalFormDTO, options?: RequestOptions) => {
      return HttpClient.put(`${accountingInvoiceURL}/api/ProposalForm/${id}`, input, options);
    },
  };

  public Delete = {
    DeleteProposalForm: (id: number[], options?: RequestOptions) => {
      return HttpClient.delete(`${accountingInvoiceURL}/api/ProposalForm/${id}`, options);
    },
  };
}

export const AccountingInvoiceService = new AccountingInvoiceController();
