import { ChangeEvent, useEffect, useState } from 'react';

import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import {
  Modal,
  Form,
  Input,
  Select,
  Row,
  Typography,
  Upload,
  Button,
  Space,
  Col,
  Table,
  DatePicker,
  Progress,
  InputNumber,
} from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from './WeeklyAssignment.module.less';
import {
  AttributeDimDTO,
  Category,
  ControlAssignWorkModalName,
  eAttribute,
  eTrackerCode,
  eTypeUpdate,
  FormatDateAPI,
  formatDateDisplay,
  sMilestone,
  TargetDTO,
} from '@/common/define';
import { IssueTargetDTO, IssueTeamDTO, Status, StatusHelperControl, WeeklyAssignmentDTO } from '@/services/IssueService';
import { TeamResponse } from '@/services/TeamService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getIssueTeams,
  getSelectedWorkWeekly,
  issueActions,
  getTagsVersion,
  getCategorys,
  getTargets,
  getOtherResources,
  getTracker,
  getAttributes,
  getIssuesByParentId,
} from '@/store/issue';
import { getModalVisible, hideModal } from '@/store/modal';
import { getEmployeesByCompanyId, getProjectMembers, getSelectedProject } from '@/store/project';
import { getTeams } from '@/store/team';
import Utils from '@/utils';

// rowSelection object indicates the need for row selection
interface optionCustom {
  key: string;
  id: number;
  code: string;
  label: string;
  value: string | number;
}
export const AssignWorkDialog = () => {
  const { Option } = Select;
  const { t } = useTranslation('weeklyAssignment');
  const tCommon = useTranslation('common').t;
  const tStatus = useTranslation('status').t;
  const tCategory = useTranslation('category').t;

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const isModalOpen = useAppSelector(getModalVisible(ControlAssignWorkModalName));
  const selectedProject = useAppSelector(getSelectedProject());
  const projectMembers = useAppSelector(getProjectMembers());
  const employees = useAppSelector(getEmployeesByCompanyId());
  const issueTeam = useAppSelector(getIssueTeams());
  const teams = useAppSelector(getTeams());
  const tags = useAppSelector(getTagsVersion());
  const categorys = useAppSelector(getCategorys());
  const targets = useAppSelector(getTargets());
  const otherResources = useAppSelector(getOtherResources());
  const trackers = useAppSelector(getTracker());
  const attributes = useAppSelector(getAttributes());
  const [Units, setUnits] = useState<optionCustom[]>([]);
  const [Types, setTypes] = useState<optionCustom[]>([]);
  const [valueType, setValueType] = useState<number | undefined>(undefined);
  const [valueUnit, setValueUnit] = useState(undefined);
  const issuesByParentId = useAppSelector(getIssuesByParentId());

  const [actualWorkDayTotal, setActualWorkDayTotal] = useState(0);

  const [formKey, setFormKey] = useState(0);

    const handleReset = () => {
        // Cập nhật key để render lại form
        setFormKey(prevKey => prevKey + 1);
    };
  
  const handleChange = (newValue: any) => {
    setValueType(newValue);
  };

  const handleClear = (type: 'Types' | 'Units') => {
    switch (type) {
      case 'Types':
        setValueType(undefined);
        break;
      case 'Units':
        setValueUnit(undefined);
        break;
    }
  };

  const selectedWorkWeekly = useAppSelector(getSelectedWorkWeekly());

  const [WorkSelected, setWorkSelected] = useState<WeeklyAssignmentDTO>();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [teamsAssign, setTeamsAssign] = useState<TeamResponse[]>([]);
  const [current, setCurrent] = useState(0);
  const pageSize = 7; // Số lượng hàng mỗi trang
  const maxNumber = 999999999999999999999999;

  const handleJumpToLastPage = () => {
    const totalItems = teamsAssign.length;
    const lastPage = Math.ceil(totalItems / pageSize);
    if (lastPage !== current) setCurrent(lastPage);
  };
  //#region selectedProject
  useEffect(() => {
    // console.log(selectedWorkWeekly);
    if (selectedWorkWeekly) {
      const _valueType = getType()?.id;
      setValueType(_valueType);
      dispatch(
        issueActions.getIssueTeamsByIssueRequest({
          issueId: selectedWorkWeekly.id,
          params: {},
        }),
      );
      let { trackerId } = selectedWorkWeekly;
      let trackerIdGiaoViecHangNgay = 22;
      if (trackers && trackers.length) {
        const tracker = trackers?.find((t)=> t.code === eTrackerCode.CongViecHangTuan);
        if (tracker && tracker.id !== null && tracker.id !== undefined) trackerId = tracker.id;

        const trackerGiaoViecHangNgay = trackers?.find((t)=> t.code === eTrackerCode.GiaoViecTheoNgay);
        if (trackerGiaoViecHangNgay && trackerGiaoViecHangNgay.id !== null && trackerGiaoViecHangNgay.id !== undefined) trackerId = trackerGiaoViecHangNgay.id;
      }
      dispatch(issueActions.getOtherResourcesDimByTracker({trackerId: trackerId }));
      dispatch(
        issueActions.getIssueByParentIdRequest({
          parentId: selectedWorkWeekly.id,
          params: {
            trackerId: trackerIdGiaoViecHangNgay,
            pageSize: 10000,
            page: 1,
            paging: false,
            // startDate: selectedWorkWeekly.plannedStartDate,
            // endDate: selectedWorkWeekly.plannedStartDate,
          },
        }),
      );
    }
  }, [dispatch, selectedWorkWeekly]);

  const getTargetData = (issueTargets: IssueTargetDTO[]): IssueTargetDTO | null | undefined => {
    if (issueTargets) {
      if (issueTargets) {
        const {length} = issueTargets;
        if (length > 0) {
          const targetIssue = Utils.clone(issueTargets[length - 1]);
          return targetIssue;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    // console.log('valueType ', valueType);
  }, [valueType]);

  useEffect(() => {
    const _teamsAssign: TeamResponse[] = [];
    issueTeam?.forEach((_team)=> {
      const team = teams.find((t)=>t.id === _team.teamId);
      if (team) {
        _teamsAssign.push({
          id: team.id,
          companyId: team.companyId,
          projectId: team.projectId,
          name: team.name,
          code: team.code,
          status: team.status,
          leader_Id: team.leader_Id,
          shifts: team.shifts,
          members: team.members,
          workdays: _team.laborCount ? _team.laborCount : 0,
        })
      }
    })
    setTeamsAssign(_teamsAssign);
  }, [issueTeam])

  useEffect(()=> {
    // console.log('team ', teams);
  }, [teams])

  useEffect(() => {
    // console.log('targets ', targets);
    const _units: optionCustom[] = [];
    const _types: optionCustom[] = [];
    const currentType = getTarget();
    targets?.forEach((t: TargetDTO) => {
      if (!_units.find(u => u.value === t.unitVolume)) {
        _units.push({
          key: Utils.generateRandomString(3),
          id: t.id,
          code: t.code,
          label: t.unitVolume,
          value: t.unitVolume,
        });
      }
      if (!currentType || t.unitVolume === currentType?.targetDim?.unitVolume) {
        _types.push({
          key: Utils.generateRandomString(3),
          id: t.id,
          code: t.code,
          label: t.unitCategory,
          value: t.id,
        });
      }
    });
    setUnits(_units);
    setTypes(_types);
  }, [targets]);

  useEffect(()=> {
    // console.log('otherResources ', otherResources);
    
  }, [otherResources])

  const { Dragger } = Upload;

  const propsDragger: UploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload: file => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
    showUploadList: false,
  };

  const removefile = (file: UploadFile<any>) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  //#region handleSaveIssue
  const handleSaveIssue = (values: any) => {
    if (selectedProject && projectMembers && selectedWorkWeekly) {
      const codeProcessing = StatusHelperControl.getCodeByValue(Status.Processing.toString());
      const _type = Types.find((t) => t.id === values.type);
      const inputData: WeeklyAssignmentDTO = {
        ...selectedWorkWeekly,
        // id: selectedWorkWeekly.id,
        // workPackageId: selectedWorkWeekly.workPackageId,
        // areaId: selectedWorkWeekly.areaId,
        // assignedTo: selectedWorkWeekly.assignedTo,
        
        // status: selectedWorkWeekly.status,
        // progress: selectedWorkWeekly.progress,
        
        // actualEndDate: dayjs(selectedWorkWeekly.actualEndDate).format(FormatDateAPI),
        // actualStartDate: dayjs(selectedWorkWeekly.actualStartDate).format(FormatDateAPI),
        // attachmentLinks: selectedWorkWeekly.attachmentLinks,
        // parentId: selectedWorkWeekly?.id ? selectedWorkWeekly?.id : null,
        // projectId: selectedProject.id,
        // categoryId: values.categoryId,
        trackerId: selectedWorkWeekly.trackerId ? selectedWorkWeekly.trackerId : 20,
        // startDate: values.startDateOfWeek ? dayjs(values.startDateOfWeek).format(FormatDateAPI) : dayjs(selectedWorkWeekly.startDate).format(FormatDateAPI),
        // dueDate: values.endDateOfWeek ? dayjs(values.endDateOfWeek).format(FormatDateAPI) : dayjs(selectedWorkWeekly.dueDate).format(FormatDateAPI),
        subject: values.nameOfWork,
        description: values.jobContent,
        notes: values.note,
        // plannedStartDate: values.startDateOfWeek ? dayjs(values.startDateOfWeek).format(FormatDateAPI) : dayjs(selectedWorkWeekly.startDate).format(FormatDateAPI),
        // plannedEndDate: values.endDateOfWeek ? dayjs(values.endDateOfWeek).format(FormatDateAPI) : dayjs(selectedWorkWeekly.dueDate).format(FormatDateAPI),
        // type: values.type, // values.type number
        status: codeProcessing ? codeProcessing : selectedWorkWeekly.status,
        // status: selectedWorkWeekly.status,
        // deliveredQuantity: values.deliveredQuantity,
        // unit: values.unitNew,
        // unitPrice: values.unitPrice,
        // workdays: values.workdays,
      };
      const { actualStartDate } = inputData;
      if (!actualStartDate) {
        inputData.actualStartDate = dayjs().format(FormatDateAPI);
      }
      delete inputData.type;
      const resources = {
        deliveredQuantity: values.deliveredQuantity?.toString(),
        unit: values.unitNew?.toString(),
        type: values.type,
        unitPrice: values.unitPrice,
        workdays: values.workdays?.toString(),
        salaryDetermination: values.salaryDetermination?.toString(),
      };
      // console.log("resources ", resources);

      if (selectedWorkWeekly) {
        // if (otherResources && otherResources.length && resources.workdays !== selectedWorkWeekly.workdays) {
        //   const categoryNhanSu = otherResources.find((o)=> o.name === "Nhân Công");
        //   if (categoryNhanSu) {
        //     //#region add Resources
        //     const dataNhansu = [{
        //       requiredQuantity: resources.workdays,
        //       otherResourcesDimId: categoryNhanSu?.id
        //     }];
        //     dispatch(issueActions.addOtherResourcesDimToIssue({id: selectedWorkWeekly.id, data: dataNhansu}));
        //   }
        // }
        //#region create targetIssue
        const { issueTargets } = inputData;
        const length = issueTargets ? issueTargets.length : 0;
        // let targetIssue: IssueTargetDTO | null = getTarget();
        let targetIssue =  issueTargets ? issueTargets[length-1] : null;
        if (issueTargets && length) {
          targetIssue =  {...issueTargets[length-1]};
        }
        if (!targetIssue) {
          targetIssue = {
            issueId: inputData.id,
            targetId: null,
            planValue: '0',
            actualValue: '0',
            costPerValue: 0,
          };
        }
        targetIssue.planValue = resources.deliveredQuantity ? resources.deliveredQuantity.toString() : '0';
        targetIssue.costPerValue = resources.unitPrice ? resources.unitPrice : 0;
        const target = targets?.find(t => t.id === resources?.type);

        if (target) {
          targetIssue.targetId = target.id;
          targetIssue.targetDim = target;
          dispatch(issueActions.updateTargetToIssue({id: selectedWorkWeekly.id, data: [ targetIssue ]}));
        }

        inputData.issueTargets = [targetIssue];

        let dinhMucLuong: AttributeDimDTO = getDinhMucLuong();
        // console.log('dinhMucLuong ', dinhMucLuong);
        
        dinhMucLuong.value = resources.salaryDetermination ? resources.salaryDetermination.toString() : '0';
        if (dinhMucLuong !== null && dinhMucLuong !== undefined) {
          const { attributes } = inputData;
          const _attributes: AttributeDimDTO[] = [];
          if (attributes && attributes.length) {
            let hasDML = false;
            attributes.forEach(a => {
              if (a.code === eAttribute.Dinh_Muc_Luong) {
                hasDML = true;
                const _a = { ...a };
                _a.value = dinhMucLuong.value;
                _a.AttributeId = dinhMucLuong.AttributeId;
                _attributes.push(_a);
              } else {
                const attribute = attributes.find((_a) => _a.code === a.code);
                if (attribute && attribute.id) {
                  _attributes.push({...a, AttributeId: attribute.id});
                }
              }
            });
            if (!hasDML && dinhMucLuong) {
              _attributes.push(dinhMucLuong);
            }
          } else {
            _attributes.push(dinhMucLuong)
          }
          // console.log('_attributes', _attributes);
          inputData.issueAttributes = _attributes;
        }
        
        const teamDatas: IssueTeamDTO[] = [];
        teamsAssign.forEach(a => {
          if (a.id) {
            teamDatas.push({
              issueId: selectedWorkWeekly.id,
              teamId: a.id,
              status: 0,
              laborCount: a.workdays,
            });
          }
        });
        dispatch(issueActions.updateIssueTeamsRequest({
          teamDatas
        }));
        // console.log('inputData ', inputData);
        // console.log('targetIssue ', targetIssue);
        dispatch(
          issueActions.updateIssueRequest({
            issueId: selectedWorkWeekly.id,
            issue: inputData,
            tagVersionId: Utils.getMileStoneId(sMilestone.SetupInitialProgress, tags),
            typeUpdate: eTypeUpdate.AssignWork
          }),
        );
      }
      // dispatch(issueActions.createIssueRequest({ issue: inputData }));
      handleCancel();
    }
  };

  const getTarget = () => {
    if (selectedWorkWeekly && selectedWorkWeekly.issueTargets) {
      const { issueTargets } = selectedWorkWeekly
      if (issueTargets) {
        const { length } = issueTargets;
        if (length > 0) {
          const targetIssue = Utils.clone(issueTargets[length-1]);
          return targetIssue;
        }
      }
    }
    return null;
  }

  const handleAssign = () => {
    const _teamsAssign = [...teamsAssign]
    _teamsAssign.unshift({
      id: 0,
      companyId: 0,
      projectId: 0,
      name: '',
      code: '',
      status: 0,
      leader_Id: 0,
      shifts: [],
      members: [],
      isBlank: true,
      tempId: Utils.generateRandomString(5),
      workdays: 0,
    })
    setTeamsAssign(_teamsAssign);
    // handleJumpToLastPage();
  };

  const handleCancel = () => {
    dispatch(issueActions.setSelectedWorkWeekly(undefined));
    dispatch(hideModal({ key: ControlAssignWorkModalName }));
  };

  const handleOk = () => form.submit();

  const onChangeTeam = (event: any, record: TeamResponse, selected: any) => {
    const teamSelected: TeamResponse | undefined = teams.find(t => t.id === selected.value);
    if (teamSelected) {
      const _teamsAssign = teamsAssign.map((t)=> {
        if ((t.tempId === record.tempId && record.tempId) || (t.id === record.id && !record.tempId)) {
          // teamSelect.workdays = record.workdays;
          return {
            ...teamSelected, workdays: 0
          };
        } else {
          return t;
        }
      });
      if (selected && !selected.data.tempId && selectedWorkWeekly) {
        const issueTeam: IssueTeamDTO = {
          issueId: selectedWorkWeekly.id,
          teamId: selected.value,
          status: 0
        }
        dispatch(issueActions.createIssueTeamRequest({issueTeam}));
      }
      setTeamsAssign(_teamsAssign);
    }
  }

  const removeTeam = (teamRemove: TeamResponse) => {
    if (teamRemove && !teamRemove.tempId) {
      dispatch(issueActions.removeIssueTeamRequest({teamId: teamRemove.id, issueId: selectedWorkWeekly?.id}));
    } else {
      const _teamsAssign = teamsAssign.filter((t)=> t.tempId !== teamRemove.tempId);
      setTeamsAssign(_teamsAssign);
    }
  }
  
  //#region onChangeWorkdays 
  const onChangeWorkdays = (event: ChangeEvent<HTMLInputElement>, record: TeamResponse) => {
    const _teamsAssign = [...teamsAssign];
    for (let i = 0; i < _teamsAssign.length; i += 1) {
      const t = _teamsAssign[i];
      console.log('t ', t);
      
      if ((t.tempId === record.tempId && record.tempId) || (t.id === record.id && !record.tempId)) {
        t.workdays = parseFloat(event.target.value);
        break;
      }
    }
    setTeamsAssign(_teamsAssign);
  }

  const checkDisable = (team: any) => {
    const temp = teamsAssign.find((t) => t.id === team.value);
    return temp ? true : false;
  }
  
  //#region columns
  const columns: any = [
    {
      title: t('Team'),
      dataIndex: 'name',
      render: (value: string, record: TeamResponse) => {
        return (
          <Row gutter={16}>
            <Col span={19}>
              <Select
                placeholder={t('Choose the responsible team')}
                options={teams.map(x => ({ title: x.name, value: x.id, disabled: checkDisable(x) }))}
                style={{ width: '100%' }}
                defaultValue={record.name}
                disabled={!record.tempId}
                optionRender={option => {
                  return (
                    <Button
                      type={'default'}
                      disabled={checkDisable(option.data)}
                      onClick={event => {
                        event.preventDefault();
                        onChangeTeam(option.data, record, option as any);
                      }}
                      style={{ width: '100%' }}
                    >
                      <Space>{option.data.title}</Space>
                    </Button>
                  );
                }}
              />
            </Col>
            <Col span={5}>
              <Button
                icon={<DeleteOutlined style={{ color: '#f00' }} />}
                type={'text'}
                onClick={event => {removeTeam(record)}}
                style={{ width: '100%' }}
              ></Button>
            </Col>
          </Row>
        );
      },
    },
    {
      title: t('Team Leader'),
      dataIndex: 'leader_Id',
      render: (value: number, record: any) => {
        let name: string | undefined = '';
        if (value) {
          // const result = dispatch(employeeActions.getEmployeeDetailsRequest({employeeId: value}));
          // console.log(result);
          const e = employees?.find((e)=> e.id === value);
          if (e) name = `${e.lastName}${e.middleName ? ' ' + e.middleName + ' ' : ' '}${e.firstName}`;
        }
        return (<Input value={name} readOnly></Input>);
      },
    },
    {
      title: t('Workdays'),
      dataIndex: 'workdays',
      render: (value: string, record: any) => {
        return (<Input defaultValue={value} onChange={(event) => onChangeWorkdays(event, record)}></Input>);
      },
    },
  ];

  const getType = () => {
    const issueTarget = getTarget();
    if (issueTarget) {
      // return {
      //   id: issueTarget.targetDim?.id,
      //   value: issueTarget.targetDim?.id,
      // };
      return issueTarget.targetDim?.id;
    }
    return null;
  };

  const getDinhMucLuong = (): AttributeDimDTO => {
    if (selectedWorkWeekly && selectedWorkWeekly.attributes) {
      const _attributes = selectedWorkWeekly.attributes;
      if (_attributes) {
        const dinhMucLuong = _attributes.find((a) => a.code === eAttribute.Dinh_Muc_Luong);
        if (dinhMucLuong) {
          let id = dinhMucLuong.id;
          if (attributes) {
            const a = attributes.find((a) => a.code === dinhMucLuong.code);
            if (a) id = a.id;
          }
          return {...dinhMucLuong, AttributeId: id ? id : 32};
        }
      }
    }
    const dinhmucluong = attributes?.find(a=>a.code === eAttribute.Dinh_Muc_Luong);
    return {
      value: dinhmucluong ? dinhmucluong.value : '',
      name: dinhmucluong ? dinhmucluong.name : eAttribute.Dinh_Muc_Luong,
      code: dinhmucluong ? dinhmucluong.code : eAttribute.Dinh_Muc_Luong,
      valueType: dinhmucluong ? dinhmucluong.valueType : 0,
      status: dinhmucluong ? dinhmucluong.status : 0,
      notes: dinhmucluong ? dinhmucluong.notes : '',
      defaultValue: dinhmucluong ? dinhmucluong.defaultValue : '0',
      companyId: dinhmucluong ? dinhmucluong.companyId : 1,
      AttributeId: dinhmucluong && dinhmucluong.id ? dinhmucluong.id : 32
    };
  };

  //#region issuesByParentId
  useEffect(() => {
    // console.log('issuesByParentId ', issuesByParentId);
    let _actualWorkDay = 0;
    if (issuesByParentId) {
      const {results} = issuesByParentId;
      if (results.length) {
        results.forEach((a)=> {
          const t = getTargetData(a.issueTargets);
          if (t) {
            _actualWorkDay += +t?.actualValue;
          }
        })
      }
    }
    handleReset();
    setActualWorkDayTotal(_actualWorkDay);
  }, [issuesByParentId]);

  //#region getRemainingVolume
  const getRemainingVolume = () => {
    const issueTarget = getTarget();
    if (issueTarget) {
      const { planValue } = issueTarget;
      let p = Utils.fixNumber(planValue);
      let a = Utils.fixNumber(actualWorkDayTotal);
      // actualValue = planValue - tổng của actualValue của các Issue con
      return `${p-a}/${p}`
    }
    return "0/0";
  }

  //#region getRemainingAmountOfWork
  const getRemainingAmountOfWork = () => {
    if (selectedWorkWeekly) {
      const {workdays, salaryDetermination, unitPrice} = selectedWorkWeekly;
      // (workdays) Số công ước tính = thành tiền / Định mức lương
      // số công thực tế = tổng của actualValue của các Issue con * Đơn giá / Định mức lương
      // số công còn lại = Số công ước tính -  số công thực tế
      const actualWorkdays = actualWorkDayTotal * unitPrice / salaryDetermination;
      return `${Utils.fixNumber(workdays) - Utils.fixNumber(actualWorkdays)}/${Utils.fixNumber(workdays)}`;
    }
    return "0/0";
  }

  useEffect(() => {
    const remainingAmountOfWork = getRemainingAmountOfWork();
    const remainingVolume = getRemainingVolume();
    form.setFieldsValue({
      remainingAmountOfWork,
      remainingVolume,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualWorkDayTotal]);

  

  const getFirstUnit = () => {
    if (Units && Units.length > 0) {
      const unit = Units[0].code;
      const type: optionCustom[] = [];
      targets?.forEach(ta => {
        if (ta.unitVolume === unit) {
          type.push({
            key: Utils.generateRandomString(3),
            id: ta.id,
            code: ta.code,
            label: ta.unitCategory,
            value: ta.id,
          });
        }
      });
      return unit;
    }
    return [];
  };

  const getWorkday = (value: any) => {
    if (typeof value === 'number') {
      if (isNaN(value) || value === Infinity) return 0;
    }
    return value;
  };

  return (
    <Modal
      title={
        <Space direction={'vertical'}>
          <Typography.Text style={{ fontSize: '20px' }}>{t('Assign work weekly')}</Typography.Text>
          <Typography.Text>{selectedWorkWeekly?.subject}</Typography.Text>
        </Space>
      }
      centered
      open={isModalOpen}
      closable={true}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={tCommon('Save')}
      width={1300} // Đặt kích thước Modal ở đây
      footer={(_, { OkBtn, CancelBtn }) => (
        <Space>
          <OkBtn />
        </Space>
      )}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              selectedWorkWeekly: selectedWorkWeekly,
              actualWorkDay: actualWorkDayTotal,
              subject: selectedWorkWeekly && selectedWorkWeekly.subject,
              status: selectedWorkWeekly && selectedWorkWeekly.status,
              startDateOfWeek: dayjs().startOf('week'),
              endDateOfWeek: dayjs().endOf('week'),
              nameOfWork: selectedWorkWeekly?.subject,
              jobContent: selectedWorkWeekly?.description,
              notes: selectedWorkWeekly?.notes,
              deliveredQuantity: selectedWorkWeekly?.deliveredQuantity,
              unitNew: selectedWorkWeekly?.unit ? selectedWorkWeekly?.unit : getFirstUnit(),
              unit: selectedWorkWeekly?.unit ? selectedWorkWeekly?.unit : getFirstUnit(),
              type: getType(),
              unitPrice: selectedWorkWeekly?.unitPrice,
              salaryDetermination: selectedWorkWeekly?.salaryDetermination,
              workdays: getWorkday(selectedWorkWeekly?.workdays),
              category: categorys?.find(c => c.id === selectedWorkWeekly?.categoryId)?.name, // selectedWorkWeekly?.categoryId,
              nameJob: selectedWorkWeekly?.subject,
              startDate: selectedWorkWeekly?.plannedStartDate ? dayjs(selectedWorkWeekly?.plannedStartDate) : '',
              dueDate: selectedWorkWeekly?.plannedEndDate ? dayjs(selectedWorkWeekly?.plannedEndDate) : '',
              progress: selectedWorkWeekly?.progress,
              remainingVolume: getRemainingVolume(),
              remainingAmountOfWork: getRemainingAmountOfWork(),
            }}
            onFinish={handleSaveIssue}
            autoComplete="off"
            key={formKey}
          >
            <Row gutter={[16, 16]}>
              <Col span={24} md={12}>
                <Form.Item
                  label={<Typography style={{ fontWeight: 'bold' }}>{t('Start day of the week')}</Typography>}
                  name="startDateOfWeek"
                >
                  <DatePicker style={{ width: '100%' }} format={formatDateDisplay} allowClear={false}/>
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label={<Typography style={{ fontWeight: 'bold' }}>{t('End day of the week')}</Typography>}
                  name="endDateOfWeek"
                >
                  <DatePicker style={{ width: '100%' }} format={formatDateDisplay} allowClear={false}/>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={
                    <Typography style={{ fontWeight: 'bold' }}>{t('Name of work assigned for this week')}</Typography>
                  }
                  name="nameOfWork"
                  rules={[{ required: true, message: t('Please input name of work assigned for this week!') }]}
                >
                  <Input placeholder={t('Name of work assigned for this week')} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<Typography style={{ fontWeight: 'bold' }}>{t('Job content')}</Typography>}
                  name="jobContent"
                  rules={[{ required: true, message: t('Please input job content!') }]}
                >
                  <Input placeholder={t('Enter job content')} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<Typography style={{ fontWeight: 'bold' }}>{t('Note')}</Typography>}
                  name="note"
                  rules={[{ required: false, message: t('Please input name!') }]}
                >
                  <Input placeholder={t('Enter work notes')} />
                </Form.Item>
              </Col>
              {
                //#region Dòng Target
              }
              <Col span={24} md={8}>
                <Form.Item
                  label={<Typography style={{ fontWeight: 'bold' }}>{t('Delivered Quantity')}</Typography>}
                  name="deliveredQuantity"
                >
                  <InputNumber max={maxNumber} min={0} controls={false} placeholder={t('Enter volume')} className={styles.inputItem}/>
                </Form.Item>
              </Col>
              <Col span={24} md={8}>
                <Form.Item
                  label={<Typography style={{ fontWeight: 'bold' }}>{t('Unit Full Text')}</Typography>}
                  name="unitNew"
                  rules={[{ required: false, message: t('Please input supervisor!') }]}
                >
                  {/* <Select
                    options={Units.map(x => ({ key: x.key, label: x.label, value: x.value }))}
                    placeholder={t('Choose the unit of measurement')}
                  /> */}
                  <Select
                    options={Units.map(x => ({ key: x.key, label: x.label, value: x.value }))}
                    placeholder={t('Choose the unit of measurement')}
                    onChange={event => {
                      const type: optionCustom[] = [];
                      targets?.forEach(ta => {
                        if (ta.unitVolume === event) {
                          type.push({
                            key: Utils.generateRandomString(3),
                            id: ta.id,
                            code: ta.code,
                            label: ta.unitCategory,
                            value: ta.id,
                          });
                        }
                      });
                      // handleClear('Types');
                      setTypes(type);
                    }}
                  >
                    {Units.map(item => (
                      <Option key={item.key} value={item.value} name={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} md={8}>
                <Form.Item label={<Typography style={{ fontWeight: 'bold' }}>{t('Type')}</Typography>} name="type"
                rules={[{ required: true, message: t('Vui lòng chọn loại') }]}>
                  <Select
                    value={valueType}
                    onChange={handleChange}
                    options={Types.map(x => ({ key: x.key, label: x.label, value: x.value }))}
                    placeholder={t('Choose type')}
                  >
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} md={8}>
                <Form.Item
                  label={<Typography style={{ fontWeight: 'bold' }}>{t('Unit price')}</Typography>}
                  name="unitPrice"
                >
                  <InputNumber max={maxNumber} min={0} controls={false} placeholder={t('Enter unit price')} className={styles.inputItem}/>
                </Form.Item>
              </Col>
              <Col span={24} md={8}>
                <Form.Item
                  label={<Typography style={{ fontWeight: 'bold' }}>{t('Salary determination')}</Typography>}
                  name="salaryDetermination"
                >
                  <InputNumber max={maxNumber} min={0} controls={false} placeholder={t('Input Salary determination')} className={styles.inputItem}/>
                </Form.Item>
              </Col>
              <Col span={24} md={8}>
                <Form.Item
                  label={<Typography style={{ fontWeight: 'bold' }}>{t('Workdays')}</Typography>}
                  name="workdays"
                >
                  <InputNumber readOnly max={maxNumber} min={0} controls={false} placeholder={t('Enter the number')} className={styles.inputItem}/>
                </Form.Item>
              </Col>
              {
                //#region Thông tin Issue
              }
              <Col span={24}>
                <Row
                  style={{
                    border: '1px solid rgb(0, 255, 213)',
                    borderRadius: '4px',
                    padding: '8px 0',
                    margin: 0,
                  }}
                  gutter={[16, 16]}
                >
                  <Col span={24} md={6}>
                    <Form.Item
                      label={<Typography style={{ fontWeight: 'bold' }}>{t('Name category')}</Typography>}
                      name="category"
                    >
                      <Select disabled options={Category(categorys, tCategory)} optionLabelProp="id" value={selectedWorkWeekly?.categoryId}/>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={18}>
                    <Form.Item
                      label={<Typography style={{ fontWeight: 'bold' }}>{t('The name of the job')}</Typography>}
                      name="nameJob"
                    >
                      <Input readOnly/>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={8}>
                    <Form.Item
                      label={<Typography style={{ fontWeight: 'bold' }}>{t('Start day')}</Typography>}
                      name="startDate"
                    >
                      <DatePicker disabled format={formatDateDisplay}/>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={8}>
                    <Form.Item
                      label={<Typography style={{ fontWeight: 'bold' }}>{t('End date')}</Typography>}
                      name="dueDate"
                    >
                      <DatePicker disabled  format={formatDateDisplay}/>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={8}>
                    <Form.Item
                      label={<Typography style={{ fontWeight: 'bold' }}>{t('Progress has been made')}</Typography>}
                      name="progress"
                    >
                      <Progress percent={selectedWorkWeekly?.progress ? selectedWorkWeekly?.progress : 0} />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={8}>
                    <Form.Item
                      label={
                        <Typography style={{ fontWeight: 'bold' }}>{`${t('Remaining volume')}/ ${t(
                          'Plan',
                        )}`}</Typography>
                      }
                      name="remainingVolume"
                    >
                      <Input readOnly/>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={6}>
                    <Form.Item
                      label={<Typography style={{ fontWeight: 'bold' }}>{t('Unit Full Text')}</Typography>}
                      name="unit"
                    >
                      <Select disabled allowClear options={Units.map(x => ({ label: x.label, value: x.value }))}/>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={10}>
                    <Form.Item
                      label={<Typography style={{ fontWeight: 'bold' }}>{t('Remaining amount of work')}</Typography>}
                      name="remainingAmountOfWork"
                    >
                      <Input readOnly/>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={12}>
          <Table
            rowKey={record => {
              const { id } = record;
              return id ? id.toString() : Utils.generateRandomString(5);
            }}
            columns={columns}
            dataSource={teamsAssign}
            scroll={{
              x: 450,
              y: 500,
            }}
            // pagination={{
            //   current: current,
            //   pageSize: pageSize,
            //   onChange: page => setCurrent(page),
            // }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      size="middle"
                      style={{
                        width: '80px',
                      }}
                      onClick={() => {
                        handleAssign();
                      }}
                    />
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </Col>
      </Row>
    </Modal>
  );
};
