import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

import { JwtDecoded } from '@/common/define';
import { setToken } from '@/services/HttpClient';

interface AppState {
  language: string;
  auth?: any;
  captcha?: any;
  activeMenu?: any;
}

const initialState: AppState = {
  language: 'vi',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    ping: (state) => {},
    // prettier-ignore
    loginRequest: (state, action: PayloadAction<{ input: any; callback?: VoidFunction }>) => {},
    loginSuccess: (state, action) => {
      const { loginResponse, loginData } = action.payload;
      const { access_token, refresh_token } = loginResponse;
      const decoded: JwtDecoded = jwtDecode(access_token);
      setToken(access_token);
      const auth = {
        user: JSON.parse(decoded.profile),
        token: access_token,
        refresh_token,
        remember: loginData.remember,
        roles: decoded.role,
        orgRoles: decoded.orgRoles,
        company: {
          id: decoded.CompanyId ? decoded.CompanyId : 3,
          orgId: decoded.OrgId
        }
      };
      state.auth = auth;
      state.captcha = undefined;
    },
    getCaptcha: (state, action) => {},
    setCaptcha: (state, action) => {
      state.captcha = action.payload;
    },
    logout: (state, action: PayloadAction<{ callback?: VoidFunction }>) => {
      const { callback } = action.payload;
      setToken(null);
      if (callback) {
        callback();
      }
    },
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
    }
  },
});

export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;
