import React, { useState, useEffect } from 'react';

import { DownloadOutlined, EllipsisOutlined, DownOutlined } from '@ant-design/icons';
import { Table, Button, DatePicker, Dropdown, Menu, Input } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import styles from './AdvancePlan.module.css';

interface DataType {
  key: string;
  id: string;
  manv: string;
  tennv: string;
  donvitinh: string;
  tienung12: number;
  quycongdoan: number;
  tongcong: number;
}

const initialDataSource: DataType[] = [
  {
    key: '1',
    id: '1',
    manv: '123',
    tennv: 'Nguyễn Khắc An',
    donvitinh: 'VND',
    tienung12: 4000000,
    quycongdoan: 10000,
    tongcong: 0,
  },
  {
    key: '2',
    id: '2',
    manv: '123',
    tennv: 'Trần Thị Bình',
    donvitinh: 'VND',
    tienung12: 2000000,
    quycongdoan: 10000,
    tongcong: 0,
  },
  {
    key: '3',
    id: '3',
    manv: '123',
    tennv: 'Thái Chinh',
    donvitinh: 'VND',
    tienung12: 1000000,
    quycongdoan: 10000,
    tongcong: 0,
  },
  {
    key: '4',
    id: '4',
    manv: '112',
    tennv: 'Vi Ngọc Chung',
    donvitinh: 'VND',
    tienung12: 1000000,
    quycongdoan: 10000,
    tongcong: 0,
  },
  {
    key: '5',
    id: '5',
    manv: '123',
    tennv: 'Lê Đào',
    donvitinh: 'VND',
    tienung12: 1000000,
    quycongdoan: 10000,
    tongcong: 0,
  },
  {
    key: '6',
    id: '6',
    manv: '123',
    tennv: 'Nguyễn Thành Cường',
    donvitinh: 'VND',
    tienung12: 0,
    quycongdoan: 10000,
    tongcong: 0,
  },
  {
    key: '7',
    id: '7',
    manv: '123',
    tennv: 'Nguyễn Văn Giao',
    donvitinh: 'VND',
    tienung12: 2000000,
    quycongdoan: 10000,
    tongcong: 0,
  },
  {
    key: '8',
    id: '8',
    manv: '123',
    tennv: 'Phong Thanh Hay',
    donvitinh: 'VND',
    tienung12: 1500000,
    quycongdoan: 10000,
    tongcong: 0,
  },
  {
    key: '9',
    id: '9',
    manv: '123',
    tennv: 'Đỗ Cường Hâu',
    donvitinh: 'VND',
    tienung12: 10000,
    quycongdoan: 10000,
    tongcong: 0,
  },
];

const calculateTongCong = (tienung12: number, quycongdoan: number): number => {
  return tienung12 + quycongdoan;
};

export const AdvancePlan: React.FC = () => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  useEffect(() => {
    const updatedDataSource = initialDataSource.map(item => ({
      ...item,
      tongcong: calculateTongCong(item.tienung12, item.quycongdoan),
    }));
    setDataSource(updatedDataSource);
  }, []);

  const { t } = useTranslation('material');
  const [selectedKey] = useState('1');

  const handleInputChange = (key: string, field: keyof DataType, value: number) => {
    setDataSource(prevDataSource =>
      prevDataSource.map(item =>
        item.key === key
          ? {
              ...item,
              [field]: value,
              tongcong: calculateTongCong(
                field === 'tienung12' ? value : item.tienung12,
                field === 'quycongdoan' ? value : item.quycongdoan,
              ),
            }
          : item,
      ),
    );
  };

  const columns: ColumnType<DataType>[] = [
    {
      title: t('Numerical order'),
      dataIndex: 'id',
      key: 'id',
      width: 78,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: t('Employee Code'),
      dataIndex: 'manv',
      key: 'manv',
      width: 96,
      align: 'center',
      className: styles.tablecell,
    },
    {
      title: t('Employee Name'),
      dataIndex: 'tennv',
      key: 'tennv',
      width: 273,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: t('Unit of measure1'),
      dataIndex: 'donvitinh',
      key: 'donvitinh',
      width: 111,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: t('Advance payment 07/12/2023'),
      dataIndex: 'tienung12',
      key: 'tienung12',
      width: 200,
      className: styles.tablecell,
      align: 'center',
      render: (value: number, record: DataType) => (
        <Input
          type="number"
          value={value}
          onChange={e => handleInputChange(record.key, 'tienung12', parseFloat(e.target.value))}
        />
      ),
    },
    {
      title: t('Union fund'),
      dataIndex: 'quycongdoan',
      key: 'quycongdoan',
      width: 200,
      className: styles.tablecell,
      align: 'center',
      render: (value: number, record: DataType) => (
        <Input
          type="number"
          value={value}
          onChange={e => handleInputChange(record.key, 'quycongdoan', parseFloat(e.target.value))}
        />
      ),
    },
    {
      title: t('Total'),
      dataIndex: 'tongcong',
      key: 'tongcong',
      width: 200,
      className: styles.tablecell,
      align: 'center',
      render: (value: number) => value.toLocaleString('vi-VN'),
    },
  ];

  const onDownload = () => {
    console.log('Download clicked');
  };

  const onEllipOutLine = () => {
    console.log('More clicked');
  };

  const menu = (
    <Menu selectedKeys={[selectedKey]}>
      <Menu.Item key="1">Ban chỉ</Menu.Item>
    </Menu>
  );

  const handleApply = () => {
    console.log('Changes saved');
  };

  return (
    <div className="MachineList">
      <div className={styles.tabheader}>
        <div className={styles.headerContent}>
          <h4>{t('advance plan 12')}</h4>
          <span className={styles.textGroup}>Công trình:</span>
          <Dropdown overlay={menu} trigger={['click']} className={styles.dropdown}>
            <Button className={styles.dropdownButton}>
              PANVC, Marina Cầu Rồng
              <DownOutlined style={{ marginLeft: '120px', fontSize: '10px' }} />
            </Button>
          </Dropdown>
          <DatePicker style={{ marginLeft: '10px' }} />
        </div>
        <div className="tab-header-diary">
          <Button type="primary" onClick={handleApply} className="apply-button">
            Lưu thay đổi
          </Button>
          <Button type="primary" icon={<DownloadOutlined />} onClick={onDownload} className="download-button"></Button>
          <Button
            type="default"
            icon={<EllipsisOutlined />}
            onClick={onEllipOutLine}
            className="ellipsis-button"
          ></Button>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        size="middle"
        style={{ maxWidth: '1801px', margin: '25px' }}
      />
    </div>
  );
};

export default AdvancePlan;
