import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';

const getState = (state: RootState) => state.accountingInvoice;

export function getProducts() {
  return createSelector([getState], state => state.products);
}

export function getWareHouses() {
  return createSelector([getState], state => state.wareHouses || []);
}

export function getProductUnits() {
  return createSelector([getState], state => state.productUnits || []);
}

export function getMachineries() {
  return createSelector([getState], state => state.machineries);
}

export function getMoneyTypes() {
  return createSelector([getState], state => state.moneyTypes || []);
}

export function getDanhSachDuyetChi() {
  return createSelector([getState], state => state.danhSachDuyetChi || []);
}

export function getDanhSachDuyetMuaHang() {
  return createSelector([getState], state => state.danhSachDuyetMuaHang || []);
}

export function getTonkho() {
  return createSelector([getState], state => state.Tonkho || []);
}

export function getTonKhoTheoNgay() {
  return createSelector([getState], state => state.TonKhoTheoNgay || []);
}

export function getProposalForms() {
  return createSelector([getState], state => state.proposalForms || []);
}

export function getQuery_danhSachDuyetChi() {
  return createSelector([getState], state => state.query_danhSachDuyetChi);
}

export function getQuery_danhSachDuyetMuaHang() {
  return createSelector([getState], state => state.query_danhSachDuyetMuaHang);
}
