import { useEffect, useState } from 'react';

import {
  PlusOutlined,
  SearchOutlined,
  AlignLeftOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, Input, Space, Tooltip, Typography , Upload} from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './FirstSalaryPm.module.less';
import { CreateUpdateIssueModalName, sMilestone, ViewState } from '@/common/define';
import GanttHeader from '@/components/Gantt/components/GanttHeader';
import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { importFileActions } from '@/store/importFile';
import { getIssueQueryParams, getIssuesView, issueActions, getTagsVersion } from '@/store/issue';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import Utils from '@/utils';

export const FirstSalaryPaymentHeader = () => {
  const { t } = useTranslation('bidding');

  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const activeMenu = useAppSelector(getActiveMenu());
  const params = useAppSelector(getIssueQueryParams());
  const [timer, setTimer] = useState<any>(null);
  const [searchStr, setSearchStr] = useState(params?.search);
  const ascending = false;
  useEffect(() => {
    setSearchStr(params?.search);
  }, [params]);


  const createIssue = () => {
    dispatch(issueActions.setEditIssuePublics(false))
    dispatch(showModal({ key: CreateUpdateIssueModalName }));
  };

  const onSearchChange = (evt: any) => {
    if (selectedProject) {
      const search = evt.target.value;
      setSearchStr(search);
      clearTimeout(timer);
      const timeoutId = setTimeout(() => {
        // dispatch(issueActions.getIssuesByMilestoneRequest({ projectId: selectedProject.id, params: { ...params, search,  ascending, tagVersionId: Utils.getMileStoneId(sMilestone.Bid, tags)}}));
      }, 500);
      setTimer(timeoutId);
    }
  };

  const handleUploadChange = (info: any) => {
    const { status } = info.file;
    if (status === 'done' || status === 'error') {
      const formData = new FormData();
      formData.append('file', info.file.originFileObj as File);
      if (selectedProject) {
        dispatch(importFileActions.importFileRequest({ file: formData }));
      }
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <div className={styles.titleContainer}>
          <Typography.Title style={{ margin: 0 }} level={4}>
            {activeMenu?.label}
          </Typography.Title>
          <Button type="primary" shape="circle" icon={<PlusOutlined />} size="small" onClick={createIssue} />
        </div>
        <div className={styles.searchContainer}>
          <Input
            allowClear
            value={searchStr}
            onChange={onSearchChange}
            suffix={searchStr ? null : <SearchOutlined />}
            style={{ borderRadius: 20, width: 200 }}
            placeholder={t('Search')}
          />
          {/* <Button type="primary" shape="circle" icon={<FilterOutlined />} size="small" />
          <Button type="link" size="small" style={{ padding: 0 }}>
            {t('Clear')}
          </Button> */}
        </div>
      </div>
    </div>
  );
};
