/* eslint-disable import/order */
import { colors } from '@/common/colors';
import { Button, Space, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { timekeepingActions } from '@/store/timekeeping';
import { useTranslation } from 'react-i18next';
import Utils from '@/utils';

export const ColumnTimeKeepingForMonth: any = ({ setOpenDetailPanel, setDataTimeEdit, dispatch }: any) => {
  const { t } = useTranslation('timeKeeping');

  const convertDate = (dateString: string) =>  {
    // Kiểm tra định dạng đầu vào
    if (!/^\d{8}$/.test(dateString)) {
        throw new Error("Định dạng ngày không hợp lệ. Sử dụng YYYYMMDD.");
    }

    // Tách năm, tháng, ngày
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    // Định dạng lại ngày
    return `${day}/${month}/${year}`;
}

  return [
    {
      title: t('workday'),
      dataIndex: 'date_Key',
      key: 'date_Key',
      width: 150,
      align: 'center',
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (text: any) => <div style={{ color: 'blue', textDecoration: 'underline' }}>{convertDate(text.toString())}</div>,
    },
    {
      title: t('number of working hours'),
      dataIndex: 'day_Hours',
      key: 'day_Hours',
      align: 'center',
    },
    {
      title: t('closing hours'),
      dataIndex: 'approved_Day_Hours',
      key: 'approved_Day_Hours',
      align: 'center',
    },
    {
      title: t('Note'),
      dataIndex: 'approved_Note',
      key: 'approved_Note',
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'option',
      key: 'option',
      align: 'center',
      fixed: 'right',
      width: 70,
      render: (_: any, record: any) => {
        const allowApprove = Utils.checkAllowApproveHour(record);
        return (
          <Space>
            <Tooltip title={'Edit'}>
              <Button
                disabled={!allowApprove}
                icon={<EditOutlined style={{ color: colors.primary }} />}
                type="text"
                size="small"
                onClick={e => {
                  e.preventDefault();
                  dispatch(timekeepingActions.setSelectedCheckInDetail(record));
                  setDataTimeEdit(record);
                  setOpenDetailPanel(true);
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};
