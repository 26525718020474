import { useEffect, useState } from 'react';

import { AutoComplete, Input, Typography } from 'antd';

import { AutoCompleteOptions } from '@/common/define';
const AutoCompleteCustom = ({
  id,
  value,
  optionsList,
  onChange,
  onSelect,
  className,
  placeholder,
}: {
  id: string;
  value: string;
  optionsList: AutoCompleteOptions[];
  onChange: (id:string, data: string) => void;
  onSelect: (id:string, data: string) => void;
  className: string;
  placeholder: string;
}) => {
  const [TextValue, setTextValue] = useState(value);
  const [options, setOptions] = useState<AutoCompleteOptions[]>([]);
  const getPanelValue = (
    searchText: string,
  ): {
    label: string;
    value: string;
  }[] => {
    if (!searchText) return [];
    return optionsList.filter(item => item.label.includes(searchText));
  };
  useEffect(() => {
    setTextValue(value);
  }, [value]);
  return (
    <AutoComplete
      className={className}
      value={TextValue}
      options={options}
      style={
        {
          // width: 200,
          // flex: 1,
        }
      }
      onSelect={(text, options) => {
        onSelect(id, text);
        setTextValue(options.label);
      }}
      onSearch={(text: any) => setOptions(getPanelValue(text))}
      onChange={text => {
        console.log(optionsList);
        setTextValue(text);
        onChange(id, text);
      }}
      placeholder={placeholder}
    >
    </AutoComplete>
  );
};
export default AutoCompleteCustom;
