import React, { useState } from 'react';

import { DownloadOutlined, EllipsisOutlined, DownOutlined } from '@ant-design/icons';
import { Table, Button, Dropdown, Menu } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import styles from './UnionDues.module.css';

interface DataType {
  key: string;
  id: string;
  tenmay: string;
  donvi: string;
  soluonghienco: string;
  vitri: string;
}

const initialDataSource: DataType[] = [
  {
    key: '1',
    id: 'BT01',
    tenmay: 'Máy xúc cát',
    donvi: 'Cái',
    soluonghienco: '5',
    vitri: 'Nhà ở chị Uyển',
  },
  {
    key: '2',
    id: 'Thep1',
    tenmay: 'Komasu 150',
    donvi: 'Cái',
    soluonghienco: '5',
    vitri: 'Nhà ở chị Uyển',
  },
  {
    key: '3',
    id: 'Thep2',
    tenmay: 'Máy đóng cọc',
    donvi: 'Cái',
    soluonghienco: '5',
    vitri: 'Nhà ở chị Uyển',
  },
  {
    key: '4',
    id: 'Thep10',
    tenmay: 'Máy đóng cọc',
    donvi: 'Cái',
    soluonghienco: '5',
    vitri: 'Nhà ở chị Uyển',
  },
  {
    key: '5',
    id: 'Thep10',
    tenmay: 'Máy đóng cọc',
    donvi: 'Cái',
    soluonghienco: '5',
    vitri: 'Nhà ở chị Uyển',
  },
  {
    key: '6',
    id: 'Thep10',
    tenmay: 'Máy đóng cọc',
    donvi: 'Cái',
    soluonghienco: '5',
    vitri: 'Nhà ở chị Uyển',
  },
  {
    key: '7',
    id: 'Thep10',
    tenmay: 'Máy đóng cọc',
    donvi: 'Cái',
    soluonghienco: '5',
    vitri: 'Nhà ở chị Uyển',
  },
  {
    key: '8',
    id: 'Thep10',
    tenmay: 'Máy đóng cọc',
    donvi: 'Cái',
    soluonghienco: '5',
    vitri: 'Nhà ở chị Uyển',
  },
  {
    key: '9',
    id: 'Thep10',
    tenmay: 'Máy đóng cọc',
    donvi: 'Cái',
    soluonghienco: '5',
    vitri: 'Nhà ở chị Uyển',
  },
  {
    key: '10',
    id: 'Thep10',
    tenmay: 'Máy đóng cọc',
    donvi: 'Cái',
    soluonghienco: '5',
    vitri: 'Nhà ở chị Uyển',
  },
  {
    key: '11',
    id: 'Thep12',
    tenmay: 'Máy đóng cọc',
    donvi: 'Cái',
    soluonghienco: '',
    vitri: '',
  },
];
export const UnionDues: React.FC = () => {
  const [dataSource] = useState<DataType[]>(initialDataSource);
  const { t } = useTranslation('material');
  const [selectedKey] = useState('1');

  const columns: ColumnType<DataType>[] = [
    {
      title: t('Numerical order'),
      dataIndex: 'id',
      key: 'id',
      width: 78,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: t('Employee Code'),
      dataIndex: 'tenmay',
      key: 'tenmay',
      width: 96,
      align: 'center',
      className: styles.tablecell,
    },
    {
      title: t('Employee Name'),
      dataIndex: 'donvi',
      key: 'donvi',
      width: 673,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: t('Unit of measure'),
      dataIndex: 'soluonghienco',
      key: 'soluonghienco',
      width: 111,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: t('amount of money'),
      dataIndex: 'vitri',
      key: 'vitri',
      width: 200,
      className: styles.tablecell,
      align: 'center',
    },
  ];

  const onDownload = () => {
    console.log('Download clicked');
  };

  const onEllipOutLine = () => {
    console.log('More clicked');
  };
  const menu = (
    <Menu selectedKeys={[selectedKey]}>
      <Menu.Item key="1">Ban chỉ</Menu.Item>
    </Menu>
  );
  const handleApply = () => {};
  return (
    <div className="MachineList">
      <div className={styles.tabheader}>
        <div className={styles.headerContent}>
          <h4>{t('union dues')}</h4>
          <span className={styles.textGroup}>Nhóm:</span>
          <Dropdown overlay={menu} trigger={['click']} className={styles.dropdown}>
            <Button className={styles.dropdownButton}>
              Ban chỉ
              <DownOutlined style={{ marginLeft: '120px', fontSize: '10px' }} />
            </Button>
          </Dropdown>
          <span className={styles.textGroup}>Cán bộ:</span>
          <Dropdown overlay={menu} trigger={['click']} className={styles.dropdown}>
            <Button className={styles.dropdownButton}>
              Nguyễn Đức Thịnh
              <DownOutlined style={{ marginLeft: '50px' }} />
            </Button>
          </Dropdown>
        </div>
        <div className="tab-header-diary">
          <Button type="primary" onClick={handleApply} className="apply-button">
            Lưu thay đổi
          </Button>
          <Button type="primary" icon={<DownloadOutlined />} onClick={onDownload} className="download-button"></Button>
          <Button
            type="default"
            icon={<EllipsisOutlined />}
            onClick={onEllipOutLine}
            className="ellipsis-button"
          ></Button>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        size="middle"
        style={{ maxWidth: '1801px', margin: '25px' }}
      />
    </div>
  );
};

export default UnionDues;
