import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ProjectInformationValue, defaultPagingParams } from '@/common/define';
import {
  EmployeesByCompanyId,
  RolesByCompanyId,
  ProjectEmployeeWithRoles,
  CreateProjectData,
  ProjectResponse,
} from '@/common/project';
import { ProjectMemberPagingResponse, ProjectMemberResponse, ProjectRolePagingResponse, ProjectStatusListPagingResponse } from '@/services/ProjectService';

interface ProjectState {
  projectList: ProjectResponse[];
  projectMemberList: ProjectEmployeeWithRoles[] | [];
  projectMembers?: ProjectMemberPagingResponse;
  selectedProject?: ProjectResponse;
  createProjectCurrentStep: number;
  createProjectInformationValue: ProjectInformationValue | null;
  projectAvatar: string;
  projects: any[] | [];
  projectById: any | null;
  projectsByCompanyId: ProjectResponse[] | [];
  selectedCompanyProject: ProjectResponse | null;
  employeesByCompanyId: EmployeesByCompanyId[] | [];
  rolesByCompanyId: RolesByCompanyId[] | [];
  createProjectData: CreateProjectData | null;
  createProjectResponse: any | null;
  projectStatus?: ProjectStatusListPagingResponse;
  queryParams: any;
  projectRoles?: ProjectRolePagingResponse;
  selectedMember?: ProjectMemberResponse;
  listLableChildren?: any[];
  listDataFileRoots: any;

  listFileLabelEdit?: any[]
}

const initialState: ProjectState = {
  projectList: [],
  projectMemberList: [],
  createProjectCurrentStep: 0,
  createProjectInformationValue: null,
  projectAvatar: '',
  projects: [],
  projectById: null,
  projectsByCompanyId: [],
  selectedCompanyProject: null,
  employeesByCompanyId: [],
  rolesByCompanyId: [],
  createProjectData: null,
  createProjectResponse: null,
  queryParams: defaultPagingParams,
  listDataFileRoots: []
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectList: (state, action) => {
      state.projectList = action.payload;
    },
    setSelectedProject: (state, action: PayloadAction<ProjectResponse | undefined>) => {
      state.selectedProject = action.payload;
    },
    setCreateProjectCurrentStep: (state, action: PayloadAction<number>) => {
      state.createProjectCurrentStep = action.payload;
    },
    setCreateProjectInformationValue: (state, action: PayloadAction<ProjectInformationValue | null>) => {
      state.createProjectInformationValue = action.payload;
    },
    setProjectAvatar: (state, action: PayloadAction<string>) => {
      state.projectAvatar = action.payload;
    },
    setProjectMemberList: (state, action: PayloadAction<ProjectEmployeeWithRoles[] | []>) => {
      state.projectMemberList = action.payload;
    },
    setCreateProjectData: (state, action: PayloadAction<CreateProjectData | null>) => {
      state.createProjectData = action.payload;
    },
    getProjectsRequest: (_state, _action) => {},
    setProjectsResponse: (state, action) => {
      state.projects = action.payload;
    },
    getProjectByIdRequest: (_state, _action: PayloadAction<string>) => {},
    setProjectByIdResponse: (state, action) => {
      state.projectById = action.payload;
    },
    getProjectsByCompanyIdRequest: (_state, _action: PayloadAction<number>) => {},
    setProjectsByCompanyIdResponse: (state, action) => {
      state.projectsByCompanyId = action.payload;
    },
    setSelectedCompanyProject: (state, action: PayloadAction<ProjectResponse | null>) => {
      state.selectedCompanyProject = action.payload;
    },
    getEmployeesByCompanyIdRequest: (_state, _action: PayloadAction<number>) => {},
    setEmployeesByCompanyIdResponse: (state, action) => {
      state.employeesByCompanyId = action.payload;
    },
    getRolesByCompanyIdRequest: (_state, _action: PayloadAction<number>) => {},
    setRolesByCompanyIdResponse: (state, action) => {
      state.rolesByCompanyId = action.payload;
    },
    createProjectRequest: (_state, _action: PayloadAction<CreateProjectData>) => {},
    setCreateProjectResponse: (state, action) => {
      state.createProjectResponse = action.payload;
    },
    updateProjectRequest: (state, action) => {},
    setProjectStatuses: (state, action) => {
      state.projectStatus = action.payload;
    },
    getStatusListRequest: (state, action) => {},
    getProjectMembersRequest: (state, action) => {},
    setProjectMembers: (state, action) => {
      state.projectMembers = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    getProjectRolesRequest: (state, action) => {},
    setProjectRoles: (state, action) => {
      state.projectRoles = action.payload;
    },
    createManyProjectMemberRequest: (state, action) => {},
    removeProjectMemberRequest: (state, action) => {},
    setSelectedMember: (state, action) => {
      state.selectedMember = action.payload;
    },
    updateProjectMemberRequest: (state, action) => {},
    createFolderRootProject: (state, action) => {},
    setCreateFolderRootProject : (state, action) => {
      // state.CreateFolderRootProject = 
    },
    getFolderRootId: (state, action) => {},
    CreateLabel: (state, action) => {},
    getLabel: (state, action) => {},
    setLabel: (state, action) => {
      state.listLableChildren = action.payload
    },
    setLabelEdit: (state, action) => {
      state.listFileLabelEdit = action.payload
    },
    getFileRoots: (state, action)=> {},
    setListFileRoots:(state, action) => {
      state.listDataFileRoots =  action.payload;
    },
    updateLabel: (state, action) => {}
  },
});

export const projectActions = projectSlice.actions;
export const projectReducer = projectSlice.reducer;
