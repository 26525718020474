import { useEffect, useState } from 'react';

import { TabsProps, Modal } from 'antd';
import dayjs from 'dayjs';

import AdditionalCost from './components/AdditionalCost';
import MachineryMaterialsList from './components/MachineryMaterialsList';
import NewMachineryMaterialList from './components/NewMachineryMaterialList';
import ProposalHistory from './components/ProposalHistory';
import { eTypeVatTuMayMoc, FormatDateAPI } from '@/common/define';
import TabHeader from '@/components/Layout/TabHeader/TabHeader';
import { accountingInvoiceActions, getMachineries, getProducts, getWareHouses } from '@/store/accountingInvoice';
import { getCurrentCompany } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker, issueActions } from '@/store/issue';
import { getSelectedProject } from '@/store/project';
interface MachineryMaterialsProps {
  type: eTypeVatTuMayMoc;
}
export const MachineryMaterials = (props: MachineryMaterialsProps) => {
  const { type } = props;
  const company = useAppSelector(getCurrentCompany());
  const selectedProject = useAppSelector(getSelectedProject());
  const producsts = useAppSelector(getProducts());
  const machineries = useAppSelector(getMachineries());
  const wareHouses = useAppSelector(getWareHouses());
  const trackers = useAppSelector(getTracker());

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(accountingInvoiceActions.GetProducts({ params: {} }));
    dispatch(accountingInvoiceActions.GetDanhSachThietBi({ params: {} }));
    dispatch(accountingInvoiceActions.GetWareHouse({ params: {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedProject) {
      dispatch(accountingInvoiceActions.GetTonKho({
        data: {
          madvcs: 'THUCHIEN',
          danhSachMaHang: [],
          ngay_kiem_tra: dayjs().format(FormatDateAPI),
          danhSachMakho: [...selectedProject.maKho]
        },
        params: {},
      }))
    } else {
      dispatch(accountingInvoiceActions.GetTonKho({
        data: {
          madvcs: 'THUCHIEN',
          danhSachMaHang: [],
          ngay_kiem_tra: dayjs().format(FormatDateAPI),
          danhSachMakho: ['TONG', 'TỔNG CCDC']
        },
        params: {},
      }))
    }
  }, [selectedProject, company]);

  useEffect(() => {
    if (company) {
      // dispatch(issueActions.getTagByCompanyIdRequest({ companyId: company.id }));
      // dispatch(issueActions.getCategoryByCompanyIdRequest({ companyId : company.id }));
      dispatch(issueActions.getTrackerByCompany({ id: company.id }));
    }
  }, [company, dispatch]);
  useEffect(() => {
    // console.log('producsts ', producsts);
    // console.log('machineries ', machineries);
  }, [producsts, machineries]);
  useEffect(() => {
    // console.log('wareHouses ', wareHouses);
  }, [wareHouses]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let tabs: TabsProps['items'] = [];
  
  switch (type) {
    case eTypeVatTuMayMoc.VatTuChinh:
      tabs = [
        {
          key: '1',
          label: 'Danh sách vật tư chính',
          children: <MachineryMaterialsList type={type} />,
        },
        {
          key: '2',
          label: 'Lịch sử đề xuất cấp vật tư chính',
          children: <ProposalHistory type={type} />,
        },
      ];
      break;
    case eTypeVatTuMayMoc.VatTuPhu:
      tabs = [
        {
          key: '1',
          label: 'Danh sách vật tư phụ',
          children: <MachineryMaterialsList type={type} />,
        },
        {
          key: '2',
          label: 'Lịch sử đề xuất cấp vật tư phụ',
          children: <ProposalHistory type={type} />,
        },
        {
          key: '3',
          label: 'Chi phí phát sinh ',
          children: <AdditionalCost type={type} />,
        },
      ];
      break;
    case eTypeVatTuMayMoc.MayMoc:
      tabs = [
        {
          key: '1',
          label: 'Danh sách máy móc',
          children: <MachineryMaterialsList type={type} />,
        },
        {
          key: '2',
          label: 'Lịch sử đề xuất cấp máy móc',
          children: <ProposalHistory type={type} />,
        },
      ];
      break;
  }
  const handleAddProposal = () => {
    setIsModalVisible(true);
  };

  const handleDownload = () => {};

  const handleSelectDate = (date: dayjs.Dayjs | null) => {
    console.log('Ngày được chọn:', date);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <TabHeader
        tabs={tabs}
        onAddProposal={handleAddProposal}
        onDownload={handleDownload}
        onSelectDate={handleSelectDate}
      />
      <Modal open={isModalVisible} onCancel={handleModalClose} footer={null} width={1250}>
        <NewMachineryMaterialList type={type} />
      </Modal>
    </div>
  );
};
