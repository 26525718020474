import React, { useEffect, useState } from 'react';

import { Table, Checkbox, PaginationProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { ColumnType, TableProps } from 'antd/es/table';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './MachineryMaterialsList.module.css';
import {
  defaultPagingParams,
  eTrackerCode,
  eTypeVatTu,
  eTypeVatTuMayMoc,
  MachineryDimDTO,
  MaterialsDim,
  MaterialsDimDTO,
  VatTuMayMocDTO,
} from '@/common/define';
import { useWindowSize } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getMachineries,
  getMaterials,
  issueActions,
  getIssueByVersion,
  getTagsVersion,
  getTracker,
  queryParamsByTagVersion,
  queryParamsMaterial,
} from '@/store/issue';
import { getLoading } from '@/store/loading';
import Utils from '@/utils';

interface MachineryMaterialsListProps {
  type: eTypeVatTuMayMoc;
}
const MachineryMaterialsList = (props: MachineryMaterialsListProps) => {
  const { type } = props;
  const [dataSource, setDataSource] = useState<VatTuMayMocDTO[]>([]);
  const { t } = useTranslation('material');
  const dispatch = useAppDispatch();
  const trackers = useAppSelector(getTracker());
  const material = useAppSelector(getMaterials());
  const machinery = useAppSelector(getMachineries());
  const paramsMaterial = useAppSelector(queryParamsMaterial());
  const isLoading = useAppSelector(getLoading(MaterialsDim.getMaterialsDim));
  const windowSize = useWindowSize();

  const getMaterialsData = (search?: any) => {
    if (trackers) {
      let trackerId = Utils.getTrackerID(trackers, eTrackerCode.GiaoViecTheoNgay);
      if (trackerId >= 0) {
        dispatch(
          issueActions.getMaterialsDimByTracker({
            trackerId,
            params: { ...search, type: 1, pageSize: 50, paging: false },
          }),
        );
        dispatch(
          issueActions.getMachinerysDimByTracker({
            trackerId,
            params: { ...search, type: 1, pageSize: 50, paging: false },
          }),
        );
      }
    }
  };

  useEffect(() => {
    getMaterialsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackers]);

  //#region material
  useEffect(() => {
    // console.log('type ', type);
    // console.log('material ', material);
    // console.log('machinery ', machinery);
    switch (type) {
      case eTypeVatTuMayMoc.VatTuChinh:
      case eTypeVatTuMayMoc.VatTuPhu:
        {
          const data: VatTuMayMocDTO[] = [];
          if (material && material.results) {
            material.results.forEach((m: MaterialsDimDTO) => {
              const dk = (type === eTypeVatTuMayMoc.VatTuChinh && m.type === eTypeVatTu.VatTuChinh) || (type === eTypeVatTuMayMoc.VatTuPhu && m.type === eTypeVatTu.VatTuPhu);
              if (dk) {
                data.push({
                  key: m.id,
                  id: m.id,
                  name: m.name,
                  unitOfMeasure: m.unitOfMeasure,
                  kldinhmuc: '',
                  tongdacap: '',
                  dexuat: '',
                  tonkho: '',
                  checkbox: false,
                });
              }
            });
            setDataSource(data);
          }
        }
        break;
      case eTypeVatTuMayMoc.MayMoc:
        {
          const data: VatTuMayMocDTO[] = [];
          if (machinery) {
            machinery.results.forEach((m: MachineryDimDTO) => {
              data.push({
                key: m.id,
                id: m.id,
                name: m.name,
                unitOfMeasure: '',
                soluonghienco: '',
                vitri: '',
                checkbox: false,
              });
            });
          }
          setDataSource(data);
        }
        break;
    }
  }, [material, machinery, type]);

  const handelData = (setDataSource: any, checked: boolean, key?: string | number) => {
    setDataSource((prevDataSource: any) => {
      const newDataSource = [...prevDataSource];
      for (let i = 0; i < newDataSource.length; i++) {
        if (key) {
          if (newDataSource[i].key === key) {
            newDataSource[i].checkbox = checked;
          }
        } else newDataSource[i].checkbox = checked;
      }
      return newDataSource;
    });
  };

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    switch (type) {
      case eTypeVatTuMayMoc.VatTuChinh:
      case eTypeVatTuMayMoc.VatTuPhu:
        handelData(setDataSource, checked);
        break;
      case eTypeVatTuMayMoc.MayMoc:
        handelData(setDataSource, checked);
        break;
    }
  };

  const handleCheckboxChange = (key: string | number, checked: boolean) => {
    switch (type) {
      case eTypeVatTuMayMoc.VatTuChinh:
      case eTypeVatTuMayMoc.VatTuPhu:
        handelData(setDataSource, checked, key);
        break;
      case eTypeVatTuMayMoc.MayMoc:
        handelData(setDataSource, checked, key);
        break;
    }
  };

  const rowClassName = (record: VatTuMayMocDTO | VatTuMayMocDTO) =>
    classnames({ [styles.selectedRow]: record.checkbox });

  const columnsVatu: ColumnType<VatTuMayMocDTO>[] = [
    {
      title: <Checkbox checked={dataSource.filter(x => !x.checkbox).length === 0} onChange={handleSelectAll} />,
      key: 'checkboxHeader',
      render: (_, record: VatTuMayMocDTO) => (
        <Checkbox checked={record.checkbox} onChange={e => handleCheckboxChange(record.key, e.target.checked)} />
      ),
      width: 64,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Material code')}</span>,
      dataIndex: 'id',
      key: 'id',
      width: 116,
      className: styles.tablecell,
      render: (text: string) => (
        <span className={['BT01', 'Thep1', 'Thep2', 'Thep10', 'Thep12'].includes(text) ? styles.underlineText : ''}>
          {text}
        </span>
      ),
      align: 'center',
    },
    {
      title: (
        <span className={styles.tableHeader}>
          {type === eTypeVatTuMayMoc.VatTuChinh ? t('Main material name') : t('Name of auxiliary material')}
        </span>
      ),
      dataIndex: 'name',
      key: 'name',
      width: 163,
      className: styles.tablecell,
      align: 'start',
    },
    {
      title: <span className={styles.tableHeader}>{t('Unit')}</span>,
      dataIndex: 'unitOfMeasure',
      key: 'unitOfMeasure',
      width: 93,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Standard Volume')}</span>,
      dataIndex: 'kldinhmuc',
      key: 'kldinhmuc',
      width: 175,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Total issued')}</span>,
      dataIndex: 'tongdacap',
      key: 'tongdacap',
      width: 137,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Also recommended')}</span>,
      dataIndex: 'dexuat',
      key: 'dexuat',
      width: 175,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Actual inventory')}</span>,
      dataIndex: 'tonkho',
      key: 'tonkho',
      width: 160,
      className: styles.tablecell,
      align: 'center',
    },
  ];

  const columnsMayMoc: ColumnType<VatTuMayMocDTO>[] = [
    {
      title: <Checkbox checked={dataSource.filter(x => !x.checkbox).length === 0} onChange={handleSelectAll} />,
      key: 'checkboxHeader',
      render: (_, record: VatTuMayMocDTO) => (
        <Checkbox checked={record.checkbox} onChange={e => handleCheckboxChange(record.key, e.target.checked)} />
      ),
      width: 64,
      align: 'center',
    },
    {
      title: t('Material code'),
      dataIndex: 'id',
      key: 'id',
      width: 142,
      className: styles.tablecell,
      render: (text: string) => (
        <span className={['BT01', 'Thep1', 'Thep2', 'Thep10', 'Thep12'].includes(text) ? styles.underlineText : ''}>
          {text}
        </span>
      ),
      align: 'center',
    },
    {
      title: t('Machine name'),
      dataIndex: 'name',
      key: 'name',
      width: 520,
      className: styles.tablecell,
    },
    {
      title: t('Unit'),
      dataIndex: 'unitOfMeasure',
      key: 'unitOfMeasure',
      width: 93,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: t('Quantity available'),
      dataIndex: 'soluonghienco',
      key: 'soluonghienco',
      width: 168,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: t('Current position'),
      dataIndex: 'vitri',
      key: 'vitri',
      width: 155,
      className: styles.tablecell,
      align: 'center',
    },
  ];

  const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const search = { ...paramsMaterial, page: current };
    getMaterialsData(search);
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    t('Paging total', { range1: range[0], range2: range[1], total });
  return (
    <div className="MaterialList">
      <header className="MaterialList-header">
        <Table
          dataSource={dataSource}
          columns={type === eTypeVatTuMayMoc.MayMoc ? columnsMayMoc : columnsVatu}
          bordered
          size="middle"
          style={{ width: '100%', height: '75vh' }}
          rowClassName={rowClassName}
          // scroll={{ x: 1000, y: windowSize[1] - 220 }}
          scroll={{ x: 1000, y: windowSize[1] - 170 }}
          pagination={false}
          // pagination={{
          //   current: paramsMaterial?.page || defaultPagingParams.page,
          //   pageSize: paramsMaterial?.pageSize > 9999 ? defaultPagingParams.pageSize : paramsMaterial?.pageSize,
          //   total: material?.queryCount || 0,
          //   responsive: true,
          //   showTotal,
          //   showSizeChanger: true,
          // }}
          loading={isLoading}
          onChange={handleTableChange}
        />
      </header>
    </div>
  );
};

export default MachineryMaterialsList;
