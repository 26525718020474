/* eslint-disable import/order */
import React, { useState } from 'react'
import { Col, Form, Input, Modal, Row, Space, TimePicker } from 'antd'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@/store/hooks';
import { getModalVisible, hideModal } from '@/store/modal';
import { UpdateTimekeepingModalName } from '@/common/define';
import { getDataUser, timekeepingActions } from '@/store/timekeeping';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const ModelUpdateTime = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const updateModel = useAppSelector(getModalVisible(UpdateTimekeepingModalName))
  const employeeSelected = useAppSelector(getDataUser())
  const [isEditTime, setIsEditTime] = useState<boolean>(false)
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken');
  const { t } = useTranslation('timeKeeping');
  const handleCancel = () => {
    dispatch(timekeepingActions.setSlectedUser(undefined));
    dispatch(hideModal({ key: UpdateTimekeepingModalName }));
  };
  const handleChangeTime = (value: any) => {
    if (employeeSelected.houseWork !== dayjs(value).tz('Asia/Ho_Chi_Minh').format('HH:mm:ss')) {
      setIsEditTime(true)
    }
  }

  const convertDate = (dateString: string) =>  {
    // Kiểm tra định dạng đầu vào
    if (!/^\d{8}$/.test(dateString)) {
        throw new Error("Định dạng ngày không hợp lệ. Sử dụng YYYYMMDD.");
    }

    // Tách năm, tháng, ngày
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    // Định dạng lại ngày
    return `${year}-${month}-${day}`;
}
  const handleSaveIssue = (value: any) => {
      const { day_Hours, approved_Day_Hours, approved_Note } = value;
      let approvedData: any =  null;
      console.log(dayjs(approved_Day_Hours).tz('Asia/Ho_Chi_Minh').format('HH:mm:ss'))
      if (employeeSelected && employeeSelected.record.length > 0) {
        approvedData = employeeSelected.record.map((item: any) => {
          return {
            working_Day: convertDate(item?.date_Key?.toString()),
            face_Identity_Id: item.face_Identity_Id,
            day_Hours: item.day_Hours,
            approved_Day_Hours: approved_Day_Hours ? dayjs(approved_Day_Hours).tz('Asia/Ho_Chi_Minh').format('HH:mm:ss') : item?.approved_Day_Hours,
            approved_Note: approved_Note ? approved_Note : '',
            team_Id: employeeSelected.teamId,
          }
        })
      } else {
        approvedData = [{
          working_Day: convertDate(employeeSelected.record.date_Key.toString()),
          face_Identity_Id: employeeSelected.record.face_Identity_Id,
          day_Hours: employeeSelected.record.day_Hours,
          approved_Day_Hours: approved_Day_Hours ? dayjs(approved_Day_Hours).tz('Asia/Ho_Chi_Minh').format('HH:mm:ss') : employeeSelected?.approved_Day_Hours,
          approved_Note: approved_Note ? approved_Note : '',
          team_Id: employeeSelected.teamId,
        }];
      }
      dispatch(timekeepingActions.approvedTimeKeepingForMonth({approvedData, accessToken, month: employeeSelected.month}))
      dispatch(hideModal({key: UpdateTimekeepingModalName}))
  }
  const handleOk = () => form.submit();
  return (
    <>
      <Modal
        title={t('Chỉnh sửa giờ chốt')}
        open={updateModel}
        centered
        okText={'Lưu'}
        width={'350px'}
        destroyOnClose
        className='modal-timekeeping'
        onCancel={handleCancel}
        onOk={handleOk}
        style={{ width: '300px', ...{ important: 'true' } }}
        footer={(_, { OkBtn, CancelBtn }) =>
            <Row style={{ margin: 0, display: 'flex', justifyContent:'end' }} align="stretch">
            <Space>
              <CancelBtn />
              <OkBtn />
            </Space>
          </Row>
        }
      > 
        <Form
          form={form}
          autoComplete="off"
          initialValues={{
            ...employeeSelected.record,
            approved_Day_Hours: employeeSelected?.record?.length > 0 ? dayjs('00:00:00', 'HH:mm:ss') : dayjs(employeeSelected.record.approved_Day_Hours, 'HH:mm:ss'),
          }}
          onFinish={handleSaveIssue}
        >
          <Row gutter={[16,16]}>
          <Col span={24}>
              <Form.Item
                label={t('closing hours')}
                name="approved_Day_Hours"
              >
                <TimePicker style={{ width: '100%' }} onChange={handleChangeTime} />
              </Form.Item>
          </Col>
            <Col span={24}>
              <Form.Item
                label={t('Note')}
                name="approved_Note"
                rules={[{ required: isEditTime, message: ('Vui lòng nhập ghi chú') }]}
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
          </Row>
          
        </Form>
      </Modal>
    </>
  )
}
