import React from 'react';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Space, Table, Tooltip } from 'antd';
import type { TableProps, PaginationProps } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './components.module.less';
import { colors } from '@/common/colors';
import {
  CreateUpdateEmployeeModalName,
  GettingEmployeeList,
  RemovingEmployee,
  defaultPagingParams,
} from '@/common/define';
import { useWindowSize } from '@/hooks';
import { EmployeeResponse } from '@/services/EmployeeService';
import { getCurrentCompany } from '@/store/app';
import { employeeActions, getEmployeeQueryParams, getEmployees } from '@/store/employee';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { showModal } from '@/store/modal';

export const CompanyEmployeeTable = () => {
  const { t } = useTranslation('employee');
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const params = useAppSelector(getEmployeeQueryParams());
  const employees = useAppSelector(getEmployees());
  const company = useAppSelector(getCurrentCompany());
  const isLoading = useAppSelector(getLoading(GettingEmployeeList));
  const isRemoving = useAppSelector(getLoading(RemovingEmployee));

  const employeeColumns: TableProps<EmployeeResponse>['columns'] = [
    {
      title: t('companyEmployee.code'),
      dataIndex: 'employeeCode',
      key: 'employeeCode',
      width: 100,
    },
    {
      title: t('companyEmployee.id'),
      dataIndex: 'identity',
      key: 'identity',
      width: 120,
    },
    {
      title: t('companyEmployee.fullName'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (value, record) => {
        const lastName = record?.lastName ?? '';
        const middleName = record?.middleName ?? '';
        const firstName = record?.firstName ?? '';
        return `${lastName + ' ' + middleName + ' ' + firstName}`;
      },
    },
    {
      title: t('companyEmployee.gender'),
      dataIndex: 'gender',
      key: 'gender',
      width: 80,
      render: value => t(value),
    },
    {
      title: t('companyEmployee.phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
      render: (value, record) => {
        return (
          <Row>
            {record.contactDetail?.mobile && <Col span={24}>{`${record.contactDetail.mobile}`}</Col>}
            {record.contactDetail?.homePhone && <Col span={24}>{`${record.contactDetail.homePhone}`}</Col>}
            {record.contactDetail?.workPhone && <Col span={24}>{`${record.contactDetail.workPhone}`}</Col>}
          </Row>
        );
      },
    },
    {
      title: t('companyEmployee.email'),
      dataIndex: 'email',
      key: 'email',
      width: 100,
      render: (value, record) => {
        return (
          <Row>
            {record.contactDetail?.workEmail && <Col span={24}>{`${record.contactDetail.workEmail}`}</Col>}
            {record.contactDetail?.otherEmail && <Col span={24}>{`${record.contactDetail.otherEmail}`}</Col>}
          </Row>
        );
      },
    },
    {
      title: t('companyEmployee.role'),
      dataIndex: 'role',
      key: 'role',
      width: 100,
    },
    {
      title: t('companyEmployee.group'),
      dataIndex: 'group',
      key: 'group',
      width: 100,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 70,
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <Space>
            <Tooltip title={t('Edit')}>
              <Button
                icon={<EditOutlined style={{ color: colors.primary }} />}
                type="text"
                size="small"
                onClick={() => editEmployee(record)}
              />
            </Tooltip>
            <Tooltip title={t('Remove')}>
              <Button
                icon={<DeleteOutlined />}
                danger
                size="small"
                type="text"
                onClick={() => confirmRemoveEmployee(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const editEmployee = (employee: any) => {
    dispatch(employeeActions.setSelectedEmployee(employee));
    dispatch(showModal({ key: CreateUpdateEmployeeModalName }));
  };

  const confirmRemoveEmployee = (employee: EmployeeResponse) => {
    Modal.confirm({
      title: t('Notification'),
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('confirmRemove', {
              name: `<strong>"${employee.lastName} ${employee.middleName} ${employee.firstName}"</strong>`,
            }),
          }}
        />
      ),
      closable: true,
      onOk: close => {
        handleRemoveEmployee(employee.id);
        close();
      },
    });
  };

  const handleRemoveEmployee = (employeeId: number) => {
    dispatch(employeeActions.removeEmployeeRequest({ employeeId, companyId: company.id }));
  };

  const handleEmpTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const search = { ...params, page: current, pageSize };
    dispatch(employeeActions.getEmployeesRequest({ companyId: company.id, params: search }));
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    t('companyEmployee.pagingTotal', { range1: range[0], range2: range[1], total });

  return (
    <div className={styles.tableContainer}>
      <Table
        rowKey={record => record.id}
        dataSource={employees?.results}
        columns={employeeColumns}
        style={{ width: '100%' }}
        size="small"
        scroll={{ x: 1000, y: windowSize[1] - 310 }}
        pagination={{
          current: params?.page || defaultPagingParams.page,
          pageSize: params?.pageSize || defaultPagingParams.pageSize,
          total: employees?.queryCount || 0,
          responsive: true,
          showTotal,
          showSizeChanger: true,
        }}
        loading={isLoading || isRemoving}
        onChange={handleEmpTableChange}
      />
    </div>
  );
};
