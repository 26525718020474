import React, { useState, useEffect } from 'react';

import {
  UpOutlined,
  DownOutlined,
  FolderOutlined,
  EllipsisOutlined,
  FolderAddOutlined,
  FileExcelOutlined,
  DeleteOutlined,
  InboxOutlined,
  UploadOutlined,
  FileExclamationOutlined,
  CheckCircleOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { Row, Col, Card, Checkbox, Modal, Upload, Button } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { UploadChangeParam } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './ProjectDocument.module.css';

export const ProjectDocument: React.FC = () => {
  const folders = ['Khoan cọc nhồi - Hồ Đình Quảng', 'Chống thấm - Nguyễn Đăng Cường', 'Cắt, đục cọc nhồi', 'Cừ lasen'];

  const [checkedItems, setCheckedItems] = useState<Record<number, boolean>>(
    folders.reduce((updatedCheckedItems, _, index) => ({ ...updatedCheckedItems, [index]: false }), {}),
  );

  const [isChecked, setIsChecked] = useState(false);
  const [selectedEllipsis, setSelectedEllipsis] = useState<number | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation('material');

  useEffect(() => {
    const allChecked = Object.values(checkedItems).every(Boolean);
    setIsChecked(allChecked);
  }, [checkedItems]);

  const handleHeaderCheckboxChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    setCheckedItems(
      folders.reduce((updatedCheckedItems, _, index) => ({ ...updatedCheckedItems, [index]: checked }), {}),
    );
  };

  const handleCardCheckboxChange = (index: number) => (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setCheckedItems(prev => ({ ...prev, [index]: checked }));
  };

  const handleEllipsisClick = (index: number) => () => {
    setSelectedEllipsis(prev => (prev === index ? null : index));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (fileList.length > 0) {
      setIsModalVisible(false);
      setIsSuccessModalVisible(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsSuccessModalVisible(false);
  };

  const handleUploadChange = (info: UploadChangeParam) => {
    setFileList(info.fileList);
  };

  const handleNavigate = () => {
    navigate('/projects/subcontractors/pay-the-subcontractor-12');
  };

  return (
    <div>
      <div className={styles.header}>
        <Row>
          <Col className={styles['header-col']} style={{ width: 10 }}>
            <Checkbox checked={isChecked} onChange={handleHeaderCheckboxChange} />
          </Col>
          <Col className={styles['header-col']} style={{ width: 200 }}>
            <span>{t('Name')}</span>
            <span className={styles['icon-container']}>
              <UpOutlined style={{ fontSize: 7 }} />
              <DownOutlined style={{ fontSize: 7 }} />
            </span>
          </Col>
          <Col className={styles['header-col']} style={{ width: 200 }}>
            <span>{t('Size')}</span>
            <span className={styles['icon-container']}>
              <UpOutlined style={{ fontSize: 7 }} />
              <DownOutlined style={{ fontSize: 7 }} />
            </span>
          </Col>
          <Col className={styles['header-col']} style={{ width: 200 }}>
            <span>{t('Modified')}</span>
            <span className={styles['icon-container']}>
              <UpOutlined style={{ fontSize: 7 }} />
              <DownOutlined style={{ fontSize: 7 }} />
            </span>
          </Col>
        </Row>
      </div>
      <div className={styles['folder-container']}>
        {folders.map((folder, index) => (
          <Card className={styles['folder-style']} key={index}>
            <Checkbox
              className={styles['checkbox-icon']}
              checked={checkedItems[index]}
              onChange={handleCardCheckboxChange(index)}
            />
            <div className={styles['folder-icon-container']}>
              <FolderOutlined className={styles['folder-icon']} />
            </div>
            <div className={styles['folder-details']}>
              <div className={styles['folder-text']}>{folder}</div>
              <EllipsisOutlined className={styles['ellipsis-icon']} onClick={handleEllipsisClick(index)} />
            </div>
            {selectedEllipsis === index && (
              <div className={styles['dropdown-card']}>
                <button className={styles['custom-button']} style={{ marginTop: 22 }}>
                  <FolderAddOutlined style={{ fontSize: 17, marginRight: 8 }} /> {t('Upload other documents')}
                </button>
                <button onClick={showModal} className={styles['custom-button']}>
                  <FileExcelOutlined style={{ fontSize: 16, marginRight: 8 }} /> {t('Payment on 12th')}
                </button>
                <button className={styles['custom-button']}>
                  <FileExcelOutlined style={{ fontSize: 16, marginRight: 8 }} />
                  {t('Payment on 27th')}
                </button>
                <button className={styles['custom-button']}>
                  <DeleteOutlined style={{ fontSize: 17, marginRight: 8 }} /> {t('Delete folder')}
                </button>
              </div>
            )}
          </Card>
        ))}
      </div>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className={styles['custom-modal']}
        width={600}
        bodyStyle={{ padding: '20px 24px' }}
        title={
          <div className={styles['modal-header']}>
            <span>{t('Payment Documents 12th')}</span>
          </div>
        }
      >
        <Upload.Dragger
          name="files"
          multiple={true}
          fileList={fileList}
          onChange={handleUploadChange}
          style={{
            background: 'rgba(250, 250, 250, 1)',
            width: '100%',
            maxWidth: 450,
            margin: 'auto',
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className={styles['ant-upload-text']}>{t('Select or drag and drop documents')}</p>
          <p className={styles['note']}>
            Support for a single or bulk upload. Strictly prohibit from uploading company data or other banned files
          </p>
        </Upload.Dragger>
        <div style={{ textAlign: 'right', marginTop: 80 }}>
          <Button
            onClick={handleCancel}
            style={{
              marginRight: 10,
              borderRadius: 50,
              border: '1px solid rgba(9, 109, 217, 1)',
              color: 'rgba(9, 109, 217, 1)',
            }}
          >
            <FileExclamationOutlined />
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={handleOk} style={{ borderRadius: 50 }}>
            {t('Upload')}
            <UploadOutlined />
          </Button>
        </div>
      </Modal>
      <Modal
        visible={isSuccessModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
        className={styles['custom-modal']}
        width={600}
        bodyStyle={{ padding: '20px 24px' }}
        title={
          <div className={styles['modal-header']}>
            <span>{t('Payment Documents 12th')}</span>
          </div>
        }
      >
        <div
          style={{
            paddingTop: '40px',
            width: '100%',
            maxWidth: 450,
            height: 180,
            textAlign: 'center',
            margin: 'auto',
            background: 'rgba(240, 240, 240, 1)',
          }}
        >
          <span
            style={{
              fontSize: 48,
              color: 'rgba(82, 196, 26, 1)',
            }}
          >
            <CheckCircleOutlined />
          </span>
          <p className={styles['ant-upload-text']}>{t('Document download successful')}</p>
          <p className={styles['note']}>{t('Data is entered into the Payment Tab on 12th')}</p>
        </div>
        <div style={{ textAlign: 'right', marginTop: 80 }}>
          <Button type="primary" onClick={handleNavigate} style={{ borderRadius: 50 }}>
            {t('Go to Payment on 12')} <ArrowRightOutlined />
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectDocument;
