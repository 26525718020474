import { catchError, concat, EMPTY, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import { employeeActions } from './employeeSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  CreateUpdateEmployeeModalName,
  GettingEmployeeList,
  RemovingEmployee,
  SavingEmployee,
  defaultPagingParams,
} from '@/common/define';
import { EmployeeService } from '@/services/EmployeeService';
import Utils from '@/utils';

const getEmployeesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.getEmployeesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { companyId, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.employee.queryParams, ...params };
      return concat(
        [startLoading({ key: GettingEmployeeList })],
        EmployeeService.Get.getEmployees(companyId, { search }).pipe(
          mergeMap(employees => {
            return [employeeActions.setQueryParams(search), employeeActions.setEmployees(employees)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [employeeActions.setEmployees(undefined)];
          }),
        ),
        [stopLoading({ key: GettingEmployeeList })],
      );
    }),
  );
};
const getDanhSachUserRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(employeeActions.getDanhSachUserRequest.match),
    switchMap(action => {
      const { options } = action.payload;
      return concat(
        of(startLoading({ key: 'getClientPortTwo' })),
        EmployeeService.Get.getDanhSachUser(options).pipe(
          map(response => {
            return employeeActions.setDanhSachUser(response);
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return EMPTY;
          }),
        ),
        of(stopLoading({ key: 'getClientPortTwo' })),
      );
    }),
  );
};

const createEmployeeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.createEmployeeRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { employee } = action.payload;
      const search = { ...defaultPagingParams, ...state.employee.queryParams };
      return concat(
        [startLoading({ key: SavingEmployee })],
        EmployeeService.Post.createEmployee(employee).pipe(
          switchMap(() => {
            return EmployeeService.Get.getEmployees(employee.companyId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification();
                return [
                  employeeActions.setEmployees(empResult),
                  employeeActions.setSelectedEmployee(undefined),
                  hideModal({ key: CreateUpdateEmployeeModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [employeeActions.setEmployees(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingEmployee })],
      );
    }),
  );
};

const updateEmployeeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.updateEmployeeRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { employeeId, employee } = action.payload;
      const search = { ...defaultPagingParams, ...state.employee.queryParams };
      return concat(
        [startLoading({ key: SavingEmployee })],
        EmployeeService.Put.updateEmployee(employeeId, employee).pipe(
          switchMap(() => {
            return EmployeeService.Get.getEmployees(employee.companyId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification();
                return [
                  employeeActions.setEmployees(empResult),
                  employeeActions.setSelectedEmployee(undefined),
                  hideModal({ key: CreateUpdateEmployeeModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [employeeActions.setEmployees(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingEmployee })],
      );
    }),
  );
};

const removeEmployeeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(employeeActions.removeEmployeeRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { employeeId, companyId } = action.payload;
      const search = { ...defaultPagingParams, ...state.employee.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingEmployee })],
        EmployeeService.delete.removeEmployee(employeeId).pipe(
          switchMap(() => {
            return EmployeeService.Get.getEmployees(companyId, { search }).pipe(
              mergeMap(empResult => {
                Utils.successNotification('Removed successfully');
                return [
                  employeeActions.setEmployees(empResult),
                  employeeActions.setSelectedEmployee(undefined),
                  employeeActions.setQueryParams(search),
                  hideModal({ key: CreateUpdateEmployeeModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [employeeActions.setEmployees(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingEmployee })],
      );
    }),
  );
};

export const employeeEpics = [
  getDanhSachUserRequest$,
  getEmployeesRequest$,
  createEmployeeRequest$,
  updateEmployeeRequest$,
  removeEmployeeRequest$,
];
