import React from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import styles from './CostsList.module.less';

interface DataType {
  key: string;
  date: string;
  description: string;
  quantity: string;
  paymentValue: string;
  cumulativeValue: string;
  contractor: string;
}

const data: DataType[] = [
  {
    key: '1',
    date: '12/07/2023',
    description: 'Xây tô lần 1',
    quantity: '10',
    paymentValue: '50.000.000',
    cumulativeValue: '50.000.000',
    contractor: 'Nhà thầu cọc nhồi',
  },
  {
    key: '2',
    date: '27/07/2023',
    description: 'Xây tô lần 2',
    quantity: '20',
    paymentValue: '30.000.000',
    cumulativeValue: '80.000.000',
    contractor: 'Nhà thầu cọc nhồi',
  },
  {
    key: '3',
    date: '12/08/2023',
    description: '',
    quantity: '',
    paymentValue: '',
    cumulativeValue: '',
    contractor: 'Nhà thầu cọc nhồi',
  },
  {
    key: '4',
    date: '27/08/2023',
    description: '',
    quantity: '',
    paymentValue: '',
    cumulativeValue: '',
    contractor: 'Nhà thầu cọc nhồi',
  },
  {
    key: '5',
    date: '12/07/2023',
    description: 'Xây tô lần 1',
    quantity: '',
    paymentValue: '50.000.000',
    cumulativeValue: '50.000.000',
    contractor: 'Nhà thầu cừ lasen',
  },
  {
    key: '6',
    date: '27/07/2023',
    description: 'Xây tô lần 2',
    quantity:'20',
    paymentValue: '30.000.000',
    cumulativeValue: '80.000.000',
    contractor: 'Nhà thầu cừ lasen',
  },
  {
    key: '7',
    date: '12/08/2023',
    description: '',
    quantity: '',
    paymentValue: '',
    cumulativeValue: '',
    contractor: 'Nhà thầu cừ lasen',
  },
  {
    key: '8',
    date: '27/08/2023',
    description: '',
    quantity: '',
    paymentValue: '',
    cumulativeValue: '',
    contractor: 'Nhà thầu cừ lasen',
  },
];

const columns: ColumnsType<DataType> = [
  {
    title: 'Ngày tháng',
    dataIndex: 'date',
    key: 'date',
    width: '20%',
  },
  {
    title: 'Diễn giải',
    dataIndex: 'description',
    key: 'description',
    width: '21%',
  },
  {
    title: 'Khối lượng',
    dataIndex: 'quantity',
    key: 'quantity',
    width: '20%',
  },
  {
    title: 'Giá trị thanh toán',
    dataIndex: 'paymentValue',
    key: 'paymentValue',
    width: '20%',
  },
  {
    title: 'Lũy kế từ đầu',
    dataIndex: 'cumulativeValue',
    key: 'cumulativeValue',
    width: '20%',
  },
  {
    title: '',
    dataIndex: 'dot',
    key: 'dot',
    render: (_, record) => (
      <Space size="middle">
        <EllipsisOutlined style={{ fontSize: '20px' }} />
      </Space>
    ),
  },
];

const CostsList: React.FC = () => {
  const { t } = useTranslation('subcontractor');

  const calculateTotalCumulative = (items: DataType[]) => {
    return items.reduce((total, item) => {
      const cumulativeValue = parseFloat(item.cumulativeValue.replace(/\./g, '').replace(/,/g, '.')) || 0;
      return total + cumulativeValue;
    }, 0).toLocaleString();
  };

  const groupedData = data.reduce((acc, item) => {
    const ctGroup = acc.find(group => group.contractor === item.contractor);
    if (ctGroup) {
      ctGroup.items.push(item);
    } else {
      acc.push({ contractor: item.contractor, items: [item] });
    }
    return acc;
  }, [] as { contractor: string; items: DataType[] }[]);

  const tableData = groupedData.map((group, index) => ({
    key: `group-${index}`,
    contractor: `${group.contractor}`,
    totalCumulative: calculateTotalCumulative(group.items),
    items: group.items,
  }));

  const expandedRowRender = (data: DataType[]) => (
    <Table columns={columns} dataSource={data} pagination={false} rowKey="key" showHeader={false} />
  );

  return (
    <div className={styles.tableContainer}>
      <div className={styles.headerContainer}>
        <h3 className={styles.headerDate}>{t('date')}</h3>
        <h3 className={styles.headerdescription}>{t('description')}</h3>
        <h3 className={styles.headerQuantity}>{t('Quantity')}</h3>
        <h3 className={styles.headerpayment}>{t('payment')}</h3>
        <h3 className={styles.headercumulative}>{t('cumulative')}</h3>
      </div>
      {tableData.map(group => (
        <div key={group.key}>
          <div className={styles.contractorRow}>
            <h2 className={`${styles.tableHeader} ${styles.tablecontractor}`}>{group.contractor}</h2>
            <h2 className={styles.totalCumulative}>
              {group.totalCumulative}
            </h2>
          </div>
          {expandedRowRender(group.items)}
        </div>
      ))}
    </div>
  );
};

export default CostsList;
