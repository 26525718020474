import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { DanhSachUserResponse, EmployeeResponse, EmployeesPagingResponse } from '@/services/EmployeeService';
import { RequestOptions } from '@/services/types';

interface EmployeeState {
  DanhSachUser: DanhSachUserResponse[];
  employees?: EmployeesPagingResponse,
  selectedEmployee?: EmployeeResponse;
  selectedEmployeeDetails?: any;
  queryParams: any;
}

const initialState: EmployeeState = {
  DanhSachUser: [],
  queryParams: defaultPagingParams
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setDanhSachUser: (state, action: PayloadAction<DanhSachUserResponse[]>) => {
      state.DanhSachUser = action.payload;
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    getDanhSachUserRequest: (state, action: PayloadAction<{ options: RequestOptions }>) => {},
    getEmployeesRequest: (state, action) => {},
    createEmployeeRequest: (state, action) => {},
    updateEmployeeRequest: (state, action) => {},
    removeEmployeeRequest: (state, action) => {},
  },
});

export const employeeActions = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;
