import { filter, withLatestFrom, switchMap, concat, mergeMap, catchError } from 'rxjs';

import { accountingInvoiceActions } from '../accountingInvoice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';
import { accountingInvoice, defaultPagingParams } from '@/common/define';
import { AccountingInvoiceService } from '@/services/AccountingInvoiceService';
import Utils from '@/utils';



const getProductRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetProducts.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.getProducts })],
        AccountingInvoiceService.Get.GetProduct(
          { search: {...params}
        }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.SetProducts(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.SetProducts(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getProducts })],
      );
    }),
  );
};

const getWareHouse$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetWareHouse.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.getWareHouse })],
        AccountingInvoiceService.Get.GetWareHouse({ search: {...params} }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.SetWareHouse(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.SetWareHouse(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getWareHouse })],
      );
    }),
  );
};

const GetProductUnit$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetProductUnit.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetProductUnit })],
        AccountingInvoiceService.Get.GetProductUnit({ search: {...params} }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setProductUnits(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setProductUnits(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetProductUnit })],
      );
    }),
  );
};

const GetDanhSachThietBi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachThietBi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachThietBi })],
        AccountingInvoiceService.Get.GetDanhSachThietBi({ search: {...params} }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setMachineries(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setMachineries(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachThietBi })],
      );
    }),
  );
};

const GetMoneyTypeList$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetMoneyTypeList.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetMoneyTypeList })],
        AccountingInvoiceService.Get.GetMoneyTypeList({ search: {...params} }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setMoneyTypes(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setMoneyTypes(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetMoneyTypeList })],
      );
    }),
  );
};

//#region PhieuNhapXuatKho
const GetDanhSachDuyetChi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachDuyetChi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachDuyetChi })],
        AccountingInvoiceService.Get.GetDanhSachDuyetChi({ search: {...params} }).pipe(
          mergeMap(results => {
            return [
              accountingInvoiceActions.setDanhSachDuyetChi(results),
              accountingInvoiceActions.setQuery_danhSachDuyetChi({params})
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              accountingInvoiceActions.setDanhSachDuyetChi(undefined),
              accountingInvoiceActions.setQuery_danhSachDuyetChi({params: undefined})
            ];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachDuyetChi })],
      );
    }),
  );
};

const GetDanhSachDuyetMuaHang$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachDuyetMuaHang.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachDuyetMuaHang })],
        AccountingInvoiceService.Get.GetDanhSachDuyetMuaHang({ search: {...params} }).pipe(
          mergeMap(results => {
            return [
              accountingInvoiceActions.setDanhSachDuyetMuaHang(results),
              accountingInvoiceActions.setQuery_danhSachDuyetMuaHang({params})
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              accountingInvoiceActions.setDanhSachDuyetMuaHang(undefined),
              accountingInvoiceActions.setQuery_danhSachDuyetMuaHang({params: undefined})
            ];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachDuyetMuaHang })],
      );
    }),
  );
};

const DuyetChi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DuyetChi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.DuyetChi })],
        AccountingInvoiceService.Post.DuyetChi(data, { search: {...params} }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.GetDanhSachDuyetChi({params: state.accountingInvoice.query_danhSachDuyetChi})];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DuyetChi })],
      );
    }),
  );
};

const HuyDuyetChi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.HuyDuyetChi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.HuyDuyetChi })],
        AccountingInvoiceService.Post.HuyDuyetChi(data, { search: {...params} }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.GetDanhSachDuyetChi({params: state.accountingInvoice.query_danhSachDuyetChi})];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.HuyDuyetChi })],
      );
    }),
  );
};

const CreatePhieuNhapXuatKho$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuNhapXuatKho.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.CreatePhieuNhapXuatKho })],
        AccountingInvoiceService.Post.CreatePhieuNhapXuatKho(data).pipe(
          switchMap(d => {
            Utils.successNotification();
            return [
              accountingInvoiceActions.GetDanhSachDuyetChi({params: state.accountingInvoice.query_danhSachDuyetChi}),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.CreatePhieuNhapXuatKho })],
      );
    }),
  );
};
const DeletePhieuNhapXuatKho$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DeletePhieuNhapXuatKho.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { ids, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: accountingInvoice.DeletePhieuNhapXuatKho })],
        AccountingInvoiceService.Post.DeletePhieuNhapXuatKho(ids).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [
              accountingInvoiceActions.GetDanhSachDuyetChi({params: state.accountingInvoice.query_danhSachDuyetChi}),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DeletePhieuNhapXuatKho })],
      );
    }),
  );
};
//#region Tồn kho
const GetTonKho$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetTonKho.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params, TonKhoTheoNgay } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetTonKho })],
        AccountingInvoiceService.Post.GetTonKho(data, { search: {...params} }).pipe(
          mergeMap(results => {
            if (TonKhoTheoNgay) {
              return [accountingInvoiceActions.setTonKhoTheoNgay(results)];
            } else {
              return [accountingInvoiceActions.setTonkho(results)];
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            if (TonKhoTheoNgay) {
              return [accountingInvoiceActions.setTonKhoTheoNgay(undefined)];
            } else {
              return [accountingInvoiceActions.setTonkho(undefined)];
            }
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetTonKho })],
      );
    }),
  );
};

//#region ProposalForm
const GetProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetProposalForm })],
        AccountingInvoiceService.Get.GetProposalForm({ search: {...params} }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setProposalForms(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setProposalForms(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetProposalForm })],
      );
    }),
  );
};

const CreateProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreateProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.CreateProposalForm })],
        AccountingInvoiceService.Post.CreateProposalForm(data, { search: { ...params } }).pipe(
          switchMap(d => {
            Utils.successNotification();
            return [
              accountingInvoiceActions.GetProposalForm({
                params: undefined,
              }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.CreateProposalForm })],
      );
    }),
  );
};
const UpdateProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.UpdateProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { id, data } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.CreateProposalForm })],
        AccountingInvoiceService.Put.UpdateProposalForm(id, data, {}).pipe(
          switchMap(() => {
            return [
              accountingInvoiceActions.GetProposalForm({
                params: undefined,
              }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.CreateProposalForm })],
      );
    }),
  );
};
const DeleteProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DeleteProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { ids, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: accountingInvoice.DeleteProposalForm })],
        AccountingInvoiceService.Delete.DeleteProposalForm(ids, {}).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [
              accountingInvoiceActions.GetProposalForm({
                params: undefined,
              }),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DeleteProposalForm })],
      );
    }),
  );
};

export const accountingInvoiceEpics = [
  getProductRequest$,
  getWareHouse$,
  GetMoneyTypeList$,
  GetDanhSachThietBi$,
  GetProductUnit$,
  GetProposalForm$,
  CreateProposalForm$,
  DeleteProposalForm$,
  UpdateProposalForm$,
  CreatePhieuNhapXuatKho$,
  DeletePhieuNhapXuatKho$,
  GetDanhSachDuyetChi$,
  GetDanhSachDuyetMuaHang$,
  GetTonKho$,
  DuyetChi$,
  HuyDuyetChi$
];
