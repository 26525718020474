import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetTonKhoDTO, MoneyTypeDTO, ProductDTO, ProductUnitDTO, PhieuNhapXuatKhoDTO, ThietBiDTO, TonKhoDTO, WareHouseDTO, ProposalFormDTO } from '@/services/AccountingInvoiceService';

interface AccountingInvoiceState {
  products: ProductDTO[];
  wareHouses: WareHouseDTO[];
  productUnits: ProductUnitDTO[];
  machineries: ThietBiDTO[];
  moneyTypes: MoneyTypeDTO[];
  danhSachDuyetChi: PhieuNhapXuatKhoDTO[];
  danhSachDuyetMuaHang: PhieuNhapXuatKhoDTO[];
  Tonkho: TonKhoDTO[];
  TonKhoTheoNgay: TonKhoDTO[];
  proposalForms: ProposalFormDTO[];
  query_danhSachDuyetChi?: any,
  query_danhSachDuyetMuaHang?: any,
}

const initialState: AccountingInvoiceState = {
  products: [],
  wareHouses: [],
  productUnits: [],
  machineries: [],
  moneyTypes: [],
  danhSachDuyetChi: [],
  danhSachDuyetMuaHang: [],
  Tonkho: [],
  TonKhoTheoNgay: [],
  query_danhSachDuyetChi: undefined,
  query_danhSachDuyetMuaHang: undefined,
  proposalForms: [],
};
const accountingInvoiceSlice = createSlice({
  name: 'accountingInvoice',
  initialState,
  reducers: {
    // Set
    SetProducts: (state: AccountingInvoiceState, action: any) => {
      state.products = action.payload;
    },
    SetWareHouse: (state: AccountingInvoiceState, action: any) => {
      state.wareHouses = action.payload;
    },
    setProductUnits: (state: AccountingInvoiceState, action: any) => {
      state.productUnits = action.payload;
    },
    setMachineries: (state: AccountingInvoiceState, action: any) => {
      state.machineries = action.payload;
    },
    setMoneyTypes: (state: AccountingInvoiceState, action: any) => {
      state.moneyTypes = action.payload;
    },
    setDanhSachDuyetChi: (state: AccountingInvoiceState, action: any) => {
      state.danhSachDuyetChi = action.payload;
    },
    setDanhSachDuyetMuaHang: (state: AccountingInvoiceState, action: any) => {
      state.danhSachDuyetMuaHang = action.payload;
    },
    setTonkho: (state: AccountingInvoiceState, action: any) => {
      state.Tonkho = action.payload;
    },
    setTonKhoTheoNgay: (state: AccountingInvoiceState, action: any) => {
      state.TonKhoTheoNgay = action.payload;
    },
    setProposalForms: (state: AccountingInvoiceState, action: any) => {
      state.proposalForms = action.payload;
    },
    // Get
    GetProducts: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { productCode?: string; productName?: string } }>,
    ) => {},
    GetWareHouse: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {},
    GetProductUnit: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {},
    GetDanhSachThietBi: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { ma_tscd?: string; ten_tscd?: string; otherFilter?: string } }>,
    ) => {},
    GetMoneyTypeList: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {},
    GetProposalForm: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {},
    GetDanhSachDuyetChi: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { CapDuyet: string; madvcs: string; tu_ngay: string; den_ngay: string } }>,
    ) => {},
    GetDanhSachDuyetMuaHang: (state: AccountingInvoiceState, action: PayloadAction<{ params: {madvcs: string, ngay_de_nghi: string} }>) => {},
    GetTonKho: (state: AccountingInvoiceState, action: PayloadAction<{ data: GetTonKhoDTO, params: any, TonKhoTheoNgay?: boolean }>) => {},
    DuyetChi: (state: AccountingInvoiceState, action: PayloadAction<{ data: number[], params: any }>) => {},
    HuyDuyetChi: (state: AccountingInvoiceState, action: PayloadAction<{ data: number[], params: any }>) => {},
    // Create
    CreatePhieuNhapXuatKho: (state: AccountingInvoiceState, action: PayloadAction<{data: PhieuNhapXuatKhoDTO, params: any }>) => {},
    CreateProposalForm: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ data: ProposalFormDTO; params: any }>,
    ) => {},
    // Delete
    DeletePhieuNhapXuatKho: (state: AccountingInvoiceState, action: PayloadAction<{ ids: number[]; params: any }>) => {},
    DeleteProposalForm: (state: AccountingInvoiceState, action: PayloadAction<{ ids: number[]; params: any }>) => {},
    // Update
    UpdateProposalForm: (state: AccountingInvoiceState, action: PayloadAction<{ id: number, data: ProposalFormDTO,  params: any }>) => {},
    // Query
    setQuery_danhSachDuyetChi: (state: AccountingInvoiceState, action: PayloadAction<{ params: { CapDuyet: string; madvcs: string; tu_ngay: string; den_ngay: string } | undefined }>) => {
      state.query_danhSachDuyetChi = action.payload;
    },
    setQuery_danhSachDuyetMuaHang: (state: AccountingInvoiceState, action: PayloadAction<{ params: {madvcs: string, ngay_de_nghi: string}| undefined }>) => {
      state.query_danhSachDuyetMuaHang = action.payload;
    },
  },
});

export const accountingInvoiceActions = accountingInvoiceSlice.actions;
export const accountingInvoiceReducer = accountingInvoiceSlice.reducer;
