import React from 'react';

import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import styles from './TranMaterials.module.css';
interface DataType {
  key: string;
  date: string;
  ma: string;
  congtrinhdc: string;
  mavattu: string;
  tenvattu: string;
  donvi: string;
  soluong: string;
  congtrinhnhandc: string;
  dot: string;
}
const data: DataType[] = [
  {
    key: '1',
    date: '01/01/2023',
    ma: '1234',
    congtrinhdc: 'Nhà Anh Huy',
    mavattu: '4567',
    tenvattu: 'Đinh',
    donvi: 'Kg',
    soluong: '5',
    congtrinhnhandc: 'Nhà chị Uyển',
    dot: '...',
  },
  {
    key: '2',
    date: '01/01/2023',
    ma: '1234',
    congtrinhdc: 'Nhà Anh Huy',
    mavattu: '4567',
    tenvattu: 'Đinh',
    donvi: 'Kg',
    soluong: '5',
    congtrinhnhandc: 'Nhà chị Uyển',
    dot: '...',
  },
  {
    key: '3',
    date: '01/01/2023',
    ma: '1234',
    congtrinhdc: 'Nhà Anh Huy',
    mavattu: '4567',
    tenvattu: 'Đinh',
    donvi: 'Kg',
    soluong: '5',
    congtrinhnhandc: 'Nhà chị Uyển',
    dot: '...',
  },
  {
    key: '4',
    date: '01/01/2023',
    ma: '1234',
    congtrinhdc: 'Nhà Anh Huy',
    mavattu: '4567',
    tenvattu: 'Đinh',
    donvi: 'Kg',
    soluong: '5',
    congtrinhnhandc: 'Nhà chị Uyển',
    dot: '...',
  },
  {
    key: '5',
    date: '02/01/2023',
    ma: '1234',
    congtrinhdc: 'Nhà Anh Huy',
    mavattu: '4567',
    tenvattu: 'Đinh',
    donvi: 'Kg',
    soluong: '5',
    congtrinhnhandc: 'Nhà chị Uyển',
    dot: '...',
  },
  {
    key: '6',
    date: '02/01/2023',
    ma: '1234',
    congtrinhdc: 'Nhà Anh Huy',
    mavattu: '4567',
    tenvattu: 'Đinh',
    donvi: 'Kg',
    soluong: '5',
    congtrinhnhandc: 'Nhà chị Uyển',
    dot: '...',
  },
  {
    key: '7',
    date: '02/01/2023',
    ma: '1234',
    congtrinhdc: 'Nhà Anh Huy',
    mavattu: '4567',
    tenvattu: 'Đinh',
    donvi: 'Kg',
    soluong: '5',
    congtrinhnhandc: 'Nhà chị Uyển',
    dot: '...',
  },
  {
    key: '8',
    date: '02/01/2023',
    ma: '1234',
    congtrinhdc: 'Nhà Anh Huy',
    mavattu: '4567',
    tenvattu: 'Đinh',
    donvi: 'Kg',
    soluong: '5',
    congtrinhnhandc: 'Nhà chị Uyển',
    dot: '...',
  },
];

const columns: ColumnsType<DataType> = [
  {
    title: 'Mã',
    dataIndex: 'ma',
    key: 'ma',
    width: '6%',
    render: (text: string) => <span className={text === '1234' ? styles.underlineText : ''}>{text}</span>,
  },
  {
    title: 'Công trình điều chuyển',
    dataIndex: 'congtrinhdc',
    key: 'congtrinhdc',
    width: '20%',
  },
  {
    title: 'Mã vật tư',
    dataIndex: 'mavattu',
    key: 'mavattu',
    width: '10%',
  },
  {
    title: 'Tên vật tư',
    dataIndex: 'tenvattu',
    key: 'tenvattu',
    width: '10%',
  },
  {
    title: 'Đơn vị tính',
    dataIndex: 'donvi',
    key: 'donvi',
    width: '10%',
  },
  {
    title: 'Số lượng',
    dataIndex: 'soluong',
    key: 'soluong',
    width: '10%',
  },
  {
    title: 'Công trình nhận điều chuyển',
    dataIndex: 'congtrinhnhandc',
    key: 'congtrinhnhandc',
    width: '20%',
  },
  { title: '', dataIndex: 'dot', key: 'dot', width: '15%' },
];
const TranMaterials: React.FC = () => {
  const { t } = useTranslation('material');
  const expandedRowRender = (data: DataType[]) => {
    return <Table columns={columns} dataSource={data} pagination={false} rowKey="key" showHeader={false} />;
  };
  const groupedData = data.reduce((acc, item) => {
    const dateGroup = acc.find(group => group.date === item.date);
    if (dateGroup) {
      dateGroup.items.push(item);
    } else {
      acc.push({ date: item.date, items: [item] });
    }
    return acc;
  }, [] as { date: string; items: DataType[] }[]);
  const tableData = groupedData.map((group, index) => ({
    key: `group-${index}`,
    date: `${t('Day')} ${group.date}`,
    items: group.items,
  }));
  return (
    <div className={styles.tableContainer}>
      <div className={styles.headerContainer}>
        <h3 className={styles.headerMa}>{t('Code')}</h3>
        <h3 className={styles.headerCongtrinh}>{t('Transfer project')}</h3>
        <h3 className={styles.headerMavattu}>{t('Material code')}</h3>
        <h3 className={styles.headerTenvattu}>{t('Material name')}</h3>
        <h3 className={styles.headerDonvitinh}>{t('Unit of measure')}</h3>
        <h3 className={styles.headerSoluong}>{t('Quantity')}</h3>
        <h3 className={styles.headerCongtrinhnhan}>{t('Project receiving transfer')}</h3>
        <h3 className={styles.headerDot}> </h3>
      </div>
      {tableData.map(group => (
        <div key={group.key}>
          <h2 className={`${styles.tableHeader} ${styles.tableDate}`}>{group.date}</h2>
          {expandedRowRender(group.items)}
        </div>
      ))}
    </div>
  );
};
export default TranMaterials;
