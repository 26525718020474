/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';

import { Col, Drawer, Empty, Input, Pagination, Row, Space, Table, Typography } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ColumnTimeKeepingForMonth } from './Column';
import { ModelUpdateTime } from './ModelUpdateTime';
import { CheckInDetail } from '../CheckInDetail';
import { UpdateTimekeepingModalName } from '@/common/define';
import { useAppSelector } from '@/store/hooks';
import { getModalVisible, showModal } from '@/store/modal';
import { getAllTimeForMonthOfOneEmployee, timekeepingActions } from '@/store/timekeeping';
import './style.css';
import Menucontext from '../Component/Menucontext';
import { useWindowSize } from '@/hooks';
import { getLoading } from '@/store/loading';
import Utils from '@/utils';

type PopupState = {
  visible: boolean;
  x: number;
  y: number;
  record: any;
};

interface TimeKeepingByDateProps {
  tTime: (key: string) => string;
  checkInDetail: any;
  queryParams: any;
  filterParams: any;
  onCloseDetailPanel: () => void;
  openDetailPanel: boolean;
  setOpenDetailPanel: any;
  checkIn: any;
  selectedMonthKeeping: number;
  option?: any;
}

export const TimeKeepingBymonth = ({
  checkIn,
  queryParams,
  onCloseDetailPanel,
  openDetailPanel,
  filterParams,
  setOpenDetailPanel,
  tTime,
  selectedMonthKeeping,
  option,
}: TimeKeepingByDateProps) => {
  const { Search } = Input;
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const [checkStrictly] = useState(false);
  const updateModel = useAppSelector(getModalVisible(UpdateTimekeepingModalName));
  const [selectedKey, setSelectedKey] = useState(null);
  const [popup, setPopup] = useState<PopupState>({ visible: false, x: 0, y: 0, record: [] });
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataTableTime, setDataTableTime] = useState<any>(null);
  const [dataTimeEdit, setDataTimeEdit] = useState<any>(null);
  const allTimeForMonthOfOneEmployee = useAppSelector(getAllTimeForMonthOfOneEmployee());
  const [selectedRowsTable, setSelectedRowsTable] = useState<any[]>([]);
  const isLoadingSavingEditTime = useAppSelector(getLoading('approvedTimekeepingRequest'));
  const isLoadingGetAllTime = useAppSelector(getLoading('getAllTimeOfOneEmployee'));
  const [fillterEmployee, setFilterEmployee] = useState<string>('');
  const [deboundceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
  const [dataFilter, setDataFilter] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  useEffect(() => {
    if (checkIn && checkIn.inSide_Team.length > 0) {
      const result = checkIn.inSide_Team.map((t: any) => {
        return t;
      });
      setDataSource(result);
    } else {
      setDataSource([]);
    }
  }, [checkIn]);

  useEffect(() => {
      if (dataSource && selectedKey) {
        setSelectedRowKeys([]);
        dispatch(
          timekeepingActions.getAllTimeOfOneEmployee({ face_identity_id: selectedKey, month: selectedMonthKeeping }),
        );
      } else {
        dispatch(timekeepingActions.setAllTimeKeepingForMonth([]));
      }
  }, [dataSource, selectedKey, selectedMonthKeeping]);


  useEffect(()=> {
    if (queryParams.team_id) {
      dispatch(timekeepingActions.setAllTimeKeepingForMonth([]));
      setSelectedKey(null);
    }
  }, [queryParams.team_id])


  useEffect(() => {
    if (allTimeForMonthOfOneEmployee && allTimeForMonthOfOneEmployee.length > 0) {
      setDataTableTime(allTimeForMonthOfOneEmployee);
    } else {
      setDataTableTime([]);
    }
  }, [allTimeForMonthOfOneEmployee, dataSource, option]);

  const handleClick = (key: any, record: any) => {
    setSelectedKey(record.id);
  };

  useEffect(() => {
    if (deboundceTimer) {
      clearTimeout(deboundceTimer);
    }
    setIsLoading(true);
    const timer = setTimeout(() => {
      if (checkIn && fillterEmployee.trim() !== '') {
        const result = checkIn.inSide_Team.filter((t: any) => {
          return t.name.toLowerCase().includes(fillterEmployee.toLowerCase());
        });
        setDataFilter(result);
      } else {
        setDataFilter([]);
      }
      setIsLoading(false);
    }, 400);
    setDebounceTimer(timer);
    return () => {
      if (deboundceTimer) {
        clearTimeout(deboundceTimer);
      }
    };
  }, [fillterEmployee]);

  const onSearch = (value: any) => {
    setFilterEmployee(value.target.value);
  };
  const headerSearch = () => {
    return (
      <Space style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <Search placeholder="Tìm kiếm nhân viên" onChange={onSearch} />
      </Space>
    );
  };

  const Paginations = () => {
    return <></>;
  };

  const rowSelection: TableRowSelection<any> = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowsTable([]);
      setSelectedRowKeys(selectedRowKeys) 
      setSelectedRowsTable(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: !Utils.checkAllowApproveHour(record), // Disable checkbox nếu record có thuộc tính disabled
    }),
  };
  
  const handleEdit = (record: any) => {
    dispatch(showModal({ key: UpdateTimekeepingModalName }));
    dispatch(timekeepingActions.setSlectedUser({ record, teamId: queryParams.team_id, month: selectedMonthKeeping }));
  };

  const rowClassName = (record: any) => {
    return record.employeeId === selectedKey ? 'selected-row' : '';
  };

  const onRow = (record: any) => ({
    onContextMenu: (event: any) => {
      event.preventDefault();
      const allowApprove = Utils.checkAllowApproveHour(record);
      if (!allowApprove) return;
      if (!popup.visible) {
        const onClickOutside = () => {
          setPopup(prevState => ({
            ...prevState,
            visible: false,
            record: selectedRowsTable.length > 0 ? selectedRowsTable : record,
          }));
          document.removeEventListener('click', onClickOutside);
        };
        document.addEventListener('click', onClickOutside);
      }
      setPopup({
        record: selectedRowsTable.length > 0 ? selectedRowsTable : record,
        visible: true,
        x: event.clientX,
        y: event.clientY,
      });
    },
  });

  const columnsName: any = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (value: any, record: any) => {
        const isSelected = selectedKey === record.id;
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            onClick={() => {
              handleClick(record.employeeId, record);
            }}
            style={{
              color: isSelected ? 'blue' : '',
              cursor: 'pointer',
            }}
            className={isSelected ? 'userName' : ''}
          >
            {value}
          </div>
        );
      },
    },
  ];

  const dataSourceTableEmployee = fillterEmployee.trim() !== '' ? dataFilter : dataSource;
  return (
    <>
      <div className="wrapperTimeKeepingMonth" style={{ position: 'relative', width: '100%' }}>
        {dataSource ? (
          <>
            {updateModel && <ModelUpdateTime />}
              <Drawer
                title={
                  <Row align="stretch">
                    <Typography.Link style={{ flex: 1, fontWeight: 600, fontSize: 18, cursor: 'default' }}>
                      {dataTimeEdit ? dataTimeEdit?.name : ''}
                    </Typography.Link>
                    <Typography.Text style={{ paddingTop: 4 }} type="secondary">
                      {dataTimeEdit ? dataTimeEdit.jobTitle : ''}
                    </Typography.Text>
                  </Row>
                }
                placement="right"
                onClose={onCloseDetailPanel}
                open={openDetailPanel}
                width={500}
              >
                <CheckInDetail
                  working_day={queryParams.working_day}
                  shifts={filterParams.shifts}
                  team_id={queryParams.team_id!}
                />
              </Drawer>
            <Row style={{ padding: 20 }}>
              <div className='wrapperTable'>
                <Col flex={'15%'}>
                  <div style={{ marginRight: 10 }}>
                    {dataSource && (
                      <Table
                        rowKey={record => record.id}
                        columns={columnsName}
                        showHeader={false}
                        dataSource={[...dataSourceTableEmployee]}
                        title={() => headerSearch()}
                        footer={() => Paginations()}
                        bordered
                        style={{width: '100%'}}
                        pagination={false}
                        className="ant-table-container"
                        rowClassName={rowClassName}
                        loading={isLoading}
                        scroll={{x: 0, y: windowSize[1] - 300 }}
                      />
                    )}
                  </div>
                </Col>
                <Col flex={"85%"}>
                  {checkIn && (
                    <div className="scrollable-table-container" style={{ height: '100%', overflow: 'auto' }}>
                        <Table
                          rowKey={record => record.date_Key}
                          columns={ColumnTimeKeepingForMonth({ setOpenDetailPanel, setDataTimeEdit, dispatch })}
                          rowSelection={{ ...rowSelection, checkStrictly, selectedRowKeys }}
                          dataSource={dataTableTime ? dataTableTime : []}
                          bordered
                          scroll={{ x: 600, y: windowSize[1] - 300 }}
                          style={{ width: '98%' }}
                          locale={{
                            emptyText: <Empty description={tTime('Please select employee')}></Empty>,
                          }}
                          onRow={onRow}
                          pagination={false}
                          loading={isLoadingSavingEditTime || isLoadingGetAllTime}
                        />
                    </div>
                  )}
                </Col>
              </div>
            </Row>
          </>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 150px)',
                backgroundColor: 'white',
                margin: 10,
              }}
            >
              <Empty
                description={
                  <>
                    <Typography.Title level={4}>{tTime('No data found based on filtering criteria')}</Typography.Title>
                    {/* <Typography.Text>{t('Try reselecting the filtering criteria to find your data')}</Typography.Text> */}
                  </>
                }
              />
            </div>
          </>
        )}
      </div>
      <Menucontext {...popup} countRows={1} handleEdit={handleEdit} />
    </>
  );
};
