import React, { useEffect, useState } from 'react';

import { PrinterOutlined } from '@ant-design/icons';
import { Table, Button, Select, DatePicker, Input, InputNumber, Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from './NewMachineryMaterialList.module.css';
import AutoCompleteCustom from '../AutoCompleteCustom';
import { AutoCompleteOptions, eTypeVatTu, eTypeVatTuMayMoc, FormatDateAPI, ProposalType } from '@/common/define';
import { ProposalFormDTO, VatTuMayMocDeXuatDTO } from '@/services/AccountingInvoiceService';
import { getProducts, getMachineries, accountingInvoiceActions, getTonKhoTheoNgay } from '@/store/accountingInvoice';
import { getCurrentCompany } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';
import Utils from '@/utils';

enum eDataFieldName {
  key,
  ma,
  ten,
  donvi,
  klkehoach,
  tonkho,
  klconlai,
  dexuat,
  ngaynhap,
  ghichu,
  isRowFuction,
}

const { Option } = Select;
let dataModifying: {[key: string]: {dexuat: string, ghichu: string}} = {};
const NewMachineryMaterialList: React.FC<{ type: eTypeVatTuMayMoc }> = ({ type }) => {
  const dispatch = useAppDispatch();
  const producsts = useAppSelector(getProducts());
  const tonKhoTheoNgay = useAppSelector(getTonKhoTheoNgay());
  const machineries = useAppSelector(getMachineries());
  const [optionsName, setOptionsName] = useState<AutoCompleteOptions[]>([]);
  const [optionsCode, setOptionsCode] = useState<AutoCompleteOptions[]>([]);
  const company = useAppSelector(getCurrentCompany());
  const selectedProject = useAppSelector(getSelectedProject());
  const summary: ProposalType = {
    key: 'summary',
    ma: '',
    ten: 'Tổng',
    donvi: '',
    klkehoach: '',
    tonkho: '',
    klconlai: '',
    dexuat: '0',
    ngaynhap: '',
    ghichu: '',
    isRowFuction: true,
  };
  const [dataSource, setDataSource] = useState<ProposalType[]>([summary]);
  const [daySelected, setDaySelected] = useState<Dayjs>(dayjs());

  const createOptionsVatTu = (loaiVT: eTypeVatTu) => {
    const oName: AutoCompleteOptions[] = [];
    const oCode: AutoCompleteOptions[] = [];
    if (producsts && producsts.length) {
      producsts.forEach(p => {
        if (p.productType === loaiVT) {
          oName.push({
            label: p.ten_vt,
            value: p.ma_vt,
          });
          oCode.push({
            label: p.ma_vt,
            value: p.ma_vt,
          });
        }
      });
    }
    setOptionsName(oName);
    setOptionsCode(oCode);
  };

  const createOptionsMayMoc = () => {
    const oName: AutoCompleteOptions[] = [];
    const oCode: AutoCompleteOptions[] = [];
    if (machineries && machineries.length) {
      machineries.forEach(p => {
        oName.push({
          label: p.ten_Tscd,
          value: p.ma_Tscd,
        });
        oCode.push({
          label: p.ma_Tscd,
          value: p.ma_Tscd,
        });
      });
    }
    setOptionsName(oName);
    setOptionsCode(oCode);
  };

  useEffect(() => {
    let reset = false;
    console.log('createOptions');
    switch (type) {
      case eTypeVatTuMayMoc.VatTuChinh:
        if (producsts && producsts.length) {
          createOptionsVatTu(eTypeVatTu.VatTuChinh);
          reset = true;
        }
        break;
      case eTypeVatTuMayMoc.VatTuPhu:
        if (producsts && producsts.length) {
          createOptionsVatTu(eTypeVatTu.VatTuPhu);
          reset = true;
        }
        break;
      case eTypeVatTuMayMoc.MayMoc:
        if (machineries && machineries.length) {
          createOptionsMayMoc();
          reset = true;
        }
        break;
    }
    reset && setDataSource([
      summary
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [producsts, machineries, type]);

  useEffect(() => {
    // console.log('optionsName ', optionsName);
  }, [optionsName]);

  useEffect(() => {
    GenerateOutgoingData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  useEffect(() => {
    console.log('tonKhoTheoNgay', tonKhoTheoNgay);
    setDataSource((prevDataSource: any) => {
      const newDataSource = [...prevDataSource];
      for (let i = 0; i < newDataSource.length; i++) {
        if (!newDataSource[i].isRowFuction) {
          let code = getData(newDataSource[i].ma);
          let kl_theo_ke_hoach = getData(newDataSource[i].kl_theo_ke_hoach);
          const tk = tonKhoTheoNgay.find(t => t.ma_vt === code);
          const tonkho = tk?.luong_ton || 0;
          const luong_nhap = tk?.luong_nhap || 0;
          const luong_xuat = tk?.luong_xuat || 0;
          newDataSource[i].klkehoach = <Input value={tonkho} readOnly />;
          newDataSource[i].tonkho = <Input value={tonkho} readOnly />;
          newDataSource[i].klconlai = <Input value={tonkho} readOnly />;
        }
      }
      return newDataSource;
    });
  }, [tonKhoTheoNgay]);

  const getData = (data: string | JSX.Element) => {
    if (typeof data === 'string') {
      return data;
    } else {
      return data?.props.value;
    }
  };

  const GenerateOutgoingData = () => {
    const data: ProposalFormDTO = {
      ma_phieu: '',
      hang_muc: '',
      ngay_tao: '',
      vat_tu_may_moc: [],
    };
    dataSource.forEach((d: ProposalType) => {
      if (!d.isRowFuction) {
        const itemMod = dataModifying[d.key];
        const item: VatTuMayMocDeXuatDTO = {
          ma: getData(d.ma),
          ten: getData(d.ten),
          dvt: getData(d.donvi),
          kl_theo_ke_hoach: getData(d.klkehoach),
          ton_kho_thuc_te: getData(d.tonkho),
          kl_con_lai: getData(d.klconlai),
          de_xuat_lan_nay: itemMod?.dexuat || '0',
          // ngay_yeu_cau_nhap_ve: getData(d.ngaynhap),
          ghi_chu: itemMod?.ghichu || '',
        };
        data.vat_tu_may_moc.push(item);
      }
    });
    console.log('GenerateOutgoingData ', data);
    return data;
  };
  const [newRows, setNewRows] = useState<Set<string>>(new Set());
  const { t } = useTranslation('material');

  const getDonViTinh = (code: string) => {
    switch (type) {
      case eTypeVatTuMayMoc.VatTuChinh:
        {
          const pr = producsts.find(v => v.ma_vt === code && v.productType === eTypeVatTu.VatTuChinh);
          if (pr) return pr.dvt;
        }
        break;
      case eTypeVatTuMayMoc.VatTuPhu:
        {
          const pr = producsts.find(v => v.ma_vt === code && v.productType === eTypeVatTu.VatTuPhu);
          if (pr) return pr.dvt;
        }
        break;
      case eTypeVatTuMayMoc.MayMoc:
        {
          const pr = machineries.find(v => v.ma_Tscd === code);
          if (pr) return pr.ccdc_Dvt;
        }
        break;
    }
    return '';
  };

  useEffect(() => {
    GetTonKho([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected]);

  const GetTonKho = (_codes: string[]) => {
    const codes = [..._codes];
    dataSource.forEach(d => {
      const code = getData(d.ma);
      if (code) codes.push(code);
    });
    const danhSachMakho = [];
    if (selectedProject) {
      danhSachMakho.push(...selectedProject.maKho);
    } else if (company && company?.maKho) {
      danhSachMakho.push(...company.maKho);
      danhSachMakho.push(...['TONG', 'TONG CCDC']);
    }
    dispatch(
      accountingInvoiceActions.GetTonKho({
        data: {
          madvcs: 'THUCHIEN',
          danhSachMaHang: codes,
          ngay_kiem_tra: daySelected.format(FormatDateAPI),
          danhSachMakho,
        },
        params: {},
        TonKhoTheoNgay: true
      }),
    );
  }

  const handelSelect = (id: string, data: string, type: eDataFieldName, key: string) => {
    const name = optionsName.find(i => i.value === data);
    let codes: string[] = [];
    switch (type) {
      case eDataFieldName.ma:
        if (name) codes.push(name.value);
        break;
      case eDataFieldName.ten:
        codes.push(data);
        break;
    }
    GetTonKho(codes);
    setDataSource((prevDataSource: any) => {
      const newDataSource = [...prevDataSource];
      for (let i = 0; i < newDataSource.length; i++) {
        if (newDataSource[i].key === id) {
          switch (type) {
            case eDataFieldName.ma:
              if (name) {
                newDataSource[i].ten = renderAutoComplete(id, optionsName, name.label, getPlaceholder(eDataFieldName.ten), eDataFieldName.ten);
                newDataSource[i].donvi = <Input value={getDonViTinh(name.value)} readOnly />;
              }
              break;
            case eDataFieldName.ten:
              newDataSource[i].ma = renderAutoComplete(id, optionsCode, data, getPlaceholder(eDataFieldName.ma), eDataFieldName.ma);
              newDataSource[i].donvi = <Input value={getDonViTinh(data)} readOnly />;
              break;
          }
        }
      }
      return newDataSource;
    });
  };

  const renderAutoComplete = (
    newKey: string,
    options: AutoCompleteOptions[],
    value: string,
    placeholder: string,
    typeHandleSelect: eDataFieldName,
  ) => {
    return (
      <AutoCompleteCustom
        className={styles.newRow}
        optionsList={options}
        id={newKey}
        onChange={(key: string, data: string) => {
          // console.log('onChange ', newKey, data);
          console.log(dataSource)
        }}
        onSelect={(id: string, data: any) => {
          handelSelect(id, data, typeHandleSelect, newKey);
        }}
        value={value}
        placeholder={placeholder}
      />
    );
  };

  const renderInputNumber = (readOnly: boolean, value: string, key: string, type: eDataFieldName) => {
    return <InputNumber className={styles.newRow} readOnly={readOnly} defaultValue={value} onChange={(v: string | null) => {
      console.log(v, dataSource);
      updateData(v, type, key);
    }} />;
  };

  const renderInput = (readOnly: boolean, value: string, key: string, type: eDataFieldName) => {
    return <Input className={styles.newRow} readOnly={readOnly} defaultValue={value} onChange={(v) => {
      console.log(v.target.value, dataSource);
      updateData(v.target.value, type, key);
    }}/>;
  };

  const updateData = (data: string | null, type: eDataFieldName, key: string) => {
    setDataSource((prevDataSource: any) => {
      const newDataSource = [...prevDataSource];
      for (let i = 0; i < newDataSource.length; i++) {
        if (newDataSource[i].key === key) {
          const prev = dataModifying[newDataSource[i].key];
          switch (type) {
            case eDataFieldName.dexuat:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, dexuat: data || '' },
              };
              newDataSource[i].dexuat = renderInput(false, data || '', key, eDataFieldName.dexuat);
              break;
            case eDataFieldName.ghichu:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, ghichu: data || '' },
              };
              newDataSource[i].ghi_chu = renderInput(false, data || '', key, eDataFieldName.ghichu);
              break;
          }
        }
      }
      return newDataSource;
    });
  }



  const getPlaceholder = (typeValue: eDataFieldName) => {
    switch (typeValue) {
      case eDataFieldName.ten:
        return type !== eTypeVatTuMayMoc.MayMoc ? t('Enter material name') : t('Enter machines name');
      case eDataFieldName.ma:
        return type !== eTypeVatTuMayMoc.MayMoc ? t('Enter material code') : t('Enter machines code');
    }
    return '';
  };

  const handleAddRow = () => {
    const newKey = (dataSource.length + 1).toString();
    console.log('handleAddRow ', newKey);
    const newRow: ProposalType = {
      key: newKey,
      ma: renderAutoComplete(newKey, optionsCode, '', getPlaceholder(eDataFieldName.ma), eDataFieldName.ma),
      ten: renderAutoComplete(newKey, optionsName, '', getPlaceholder(eDataFieldName.ten), eDataFieldName.ten),
      donvi: renderInputNumber(true, '', newKey, eDataFieldName.donvi),
      klkehoach: renderInputNumber(true, '', newKey, eDataFieldName.klkehoach),
      tonkho: renderInputNumber(true, '', newKey, eDataFieldName.tonkho),
      klconlai: renderInputNumber(true, '', newKey, eDataFieldName.klconlai),
      dexuat: renderInputNumber(false, '', newKey, eDataFieldName.dexuat),
      // ngaynhap: <DatePicker className={styles.newRow} />,
      ghichu: renderInput(false, '', newKey, eDataFieldName.ghichu),
    };
    setDataSource([...dataSource, newRow]);
    setNewRows(new Set(newRows.add(newKey)));
  };

  const columns: ColumnType<ProposalType>[] = [
    {
      title: <span className={styles.tableHeader}>{t('Material code')}</span>,
      dataIndex: 'ma',
      key: 'ma',
      width: 200,
      className: styles.tablecell,
      render: (text: string | JSX.Element) => (
        <span className={typeof text === 'string' && ['BT1', 'Thep1'].includes(text) ? styles.underlineText : ''}>
          {text}
        </span>
      ),
      align: 'center',
    },
    {
      title: (
        <span className={styles.tableHeader}>
          {type === eTypeVatTuMayMoc.MayMoc ? t('Machinery name') : t('Material name')}
        </span>
      ),
      dataIndex: 'ten',
      key: 'ten',
      width: 500,
      className: styles.tablecell,
      render: (text: string | JSX.Element) => (
        <span className={typeof text === 'string' && text === 'Tổng' ? styles.boldText : ''}>{text}</span>
      ),
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Unit')}</span>,
      dataIndex: 'donvi',
      key: 'donvi',
      width: 100,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Planned volume')}</span>,
      dataIndex: 'klkehoach',
      key: 'klkehoach',
      width: 120,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Actual inventory')}</span>,
      dataIndex: 'tonkho',
      key: 'tonkho',
      width: 175,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Remaining mass')}</span>,
      dataIndex: 'klconlai',
      key: 'klconlai',
      width: 137,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('This time proposal')}</span>,
      dataIndex: 'dexuat',
      key: 'dexuat',
      width: 175,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    // {
    //   title: <span className={styles.tableHeader}>{t('Date of entry required')}</span>,
    //   dataIndex: 'ngaynhap',
    //   key: 'ngaynhap',
    //   width: 160,
    //   render: (text: string | JSX.Element) => text,
    //   className: styles.tablecell,
    //   align: 'center',
    // },
    {
      title: <span className={styles.tableHeader}>{t('Note')}</span>,
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 300,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
  ];

  const addButtonRow: ProposalType = {
    key: (dataSource.length + 1).toString(),
    ma: (
      <Button className={styles.addButton} onClick={handleAddRow}>
        +
      </Button>
    ),
    ten: '',
    donvi: '',
    klkehoach: '',
    tonkho: '',
    klconlai: '',
    dexuat: '',
    ngaynhap: '',
    ghichu: '',
    isRowFuction: true,
  };

  const tongIndex = dataSource.findIndex(row => row.ten === 'Tổng');
  const updatedDataSource = [
    ...dataSource.slice(0, tongIndex),
    ...dataSource.slice(tongIndex + 1),
    dataSource[tongIndex],
    addButtonRow,
  ];

  function handleSave() {
    const PhieuDeXuat = GenerateOutgoingData();
    console.log('PhieuDeXuat ', PhieuDeXuat);
    // dispatch(accountingInvoiceActions.CreateProposalForm({data: PhieuDeXuat, params: {}}));
  }

  return (
    <div className={styles.container}>
      <div className={styles.formcontainer}>
        <h1 className={styles.formtitle}>
          {type === eTypeVatTuMayMoc.MayMoc
            ? t('CREATE NEW MACHINERY PROPOSAL FORM')
            : t('CREATE NEW MATERIAL PROPOSAL FORM')}
        </h1>
        <Space style={{ flex: 1}}>
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
            <span className={styles.labeltext}>
              {t('Belongs to category / Father job')}
              <span className="fadedText">{t('(Optional)')}</span>
            </span>
            <div>
              <Select defaultValue="select" className={styles.selectfield}>
                <Option value="select">Phần thân</Option>
              </Select>
            </div>
          </Space>
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
            <span className={styles.labeltext}>{t('Date of creation of ticket')}</span>
            <div>
              <DatePicker className={styles.datepicker} value={daySelected} />
            </div>
          </Space>
        </Space>
        <div className="MaterialList-header">
          <Table
            rowKey={record => {
              let key = record?.key;
              if (key === undefined || key === null) {
                key = Utils.generateRandomString(5);
              }
              return key;
            }}
            dataSource={updatedDataSource}
            columns={columns}
            pagination={false}
            bordered
            size="middle"
            scroll={{ x: 900, y: 380 }}
            rowClassName={record => {
              if (record) {
                return newRows.has(record.key) ? `${styles.newRow} ${styles.tableRow}` : styles.tableRow;
              }
              return '';
            }}
          />
        </div>
      </div>
      <div className={styles.buttonsection}>
        <Button type="primary" className={styles.button}>
          {t('Import to warehouse')}
        </Button>
        <PrinterOutlined className={styles.PrinterOutlined} />
        <Button
          type="primary"
          className={styles.button}
          onClick={() => {
            handleSave();
          }}
        >
          {t('Submit a proposal')}
        </Button>
      </div>
    </div>
  );
};

export default NewMachineryMaterialList;
