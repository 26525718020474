import { catchError, concat, filter, finalize, switchMap } from 'rxjs';

import { appActions } from './appSlice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';
import { userActions } from '../user';
import { setToken } from '@/services/HttpClient';
import { IdentityService } from '@/services/IdentityService';
import { UserService } from '@/services/UserService';
import Utils from '@/utils';

const loginRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(appActions.loginRequest.match),
    switchMap(action => {
      const { input, callback } = action.payload;
      let success = false;
      return concat(
        [startLoading({ key: 'login' })],
        IdentityService.Post.login(input).pipe(
          switchMap(loginResponse => {
            if (loginResponse.errorCode) {
              Utils.errorHandling(loginResponse);
              return [stopLoading({ key: 'login' })];
            }
            success = true;
            if (input.password) {
              // lấy user prefences ngay sau khi đăng nhập thành công
              setToken(loginResponse.access_token);
              return UserService.Get.getUserPreferences().pipe(
                switchMap(preferences => {
                  return [
                    appActions.loginSuccess({ loginResponse, loginData: input }),
                    userActions.setUserPreferences(preferences),
                    stopLoading({ key: 'login' }),
                  ];
                }),
                catchError(() => {
                  return [
                    appActions.loginSuccess({ loginResponse, loginData: input }),
                    userActions.setUserPreferences(undefined),
                    stopLoading({ key: 'login' }),
                  ];
                }),
              );
            }
            return [
              appActions.loginSuccess({ loginResponse, loginData: input }),
              userActions.getCurrentConfigRequest(),
              stopLoading({ key: 'login' }),
            ];
          }),
          catchError(error => {
            if (error.response?.CaptchaId && !input.captcha) {
              const { CaptchaId, Captcha } = error.response;
              return [appActions.setCaptcha({ CaptchaId, Captcha }), stopLoading({ key: 'login' })];
            }
            Utils.errorHandling(error);
            const { CaptchaId, Captcha } = error.response;
            if (CaptchaId) {
              return [appActions.setCaptcha({ CaptchaId, Captcha }), stopLoading({ key: 'login' })];
            }
            return [stopLoading({ key: 'login' })];
          }),
          finalize(() => {
            if (success && callback) {
              callback();
            }
          }),
        ),
      );
    }),
  );
};

const getCaptcha$: RootEpic = action$ => {
  return action$.pipe(
    filter(appActions.getCaptcha.match),
    switchMap(action => {
      return concat(
        [startLoading({ key: 'GetCaptcha' })],
        IdentityService.Get.getCaptchaByEmail(action.payload).pipe(
          switchMap(captcha => {
            return [appActions.setCaptcha(captcha)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'GetCaptcha' })],
      );
    }),
  );
};

export const appEpics = [loginRequest$, getCaptcha$];
